// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';

import type ParkingBookingRequest from '../../models/BookingRequest/ParkingBookingRequest';

import Price from '../Price';
import Button from '../Button';
import Checkbox from '../form/Checkbox';
import { autobind } from 'core-decorators';
import type Bookable from '../../models/Bookable';
import { computed } from 'mobx';

type Props = {
  bookingRequest: ParkingBookingRequest,
  onBooking: Function,
  bookable: Bookable,
  addonBookable?: boolean,
};

@observer
export default class ParkingBookingAssistant extends Component<Props> {
  @autobind
  changedCheckbox(value: boolean) {
    this.props.bookingRequest.setAcceptation(value);
  }

  @computed
  get isBooked(): boolean {
    const { bookingRequest, bookable } = this.props;
    return !!bookingRequest.bookedPackages.find((pkg) => pkg.typeId === bookable.typeId);
  }

  @computed
  get isBookable(): boolean {
    const { bookingRequest, addonBookable } = this.props;
    return this.isBooked ? false : !bookingRequest.hasBookedSomethingOfSameType || addonBookable;
  }

  renderButton() {
    const { bookingRequest, bookable, onBooking, addonBookable } = this.props;

    if (addonBookable === false) {
      return (
        <Button dark big disabled>
          Kein Parkplatz gebucht
        </Button>
      );
    }

    if (bookable.statusCode === 0) {
      return (
        <Button dark big disabled={!bookingRequest.isReady} onClick={onBooking}>
          Zur Reservierung
        </Button>
      );
    } else {
      return (
        <Button dark big disabled>
          {bookable.bookingStatus}
        </Button>
      );
    }
  }

  render() {
    const { bookingRequest, bookable } = this.props;

    return (
      <div>
        <p className="text-md">
          Parkservices können nur einmalig und persönlich reserviert werden. Sollten Sie einen anderen Service wünschen,
          können Sie die bereits reservierte Leistung kostenfrei stornieren und den gewünschten Service reservieren.
        </p>
        {this.isBookable && (
          <div className="form-checkbox-fakegroup">
            <Checkbox
              name="operatorAccepted"
              label="Mir ist bekannt, dass TUI Cruises die Anfrage zur Reservierung eines Parkplatzes lediglich vermittelt und die Bereitstellung des Parkplatzes durch den Parkservice-Dienstleister am Hafen erfolgt. Mein Vertragspartner wird der örtliche Parkservice-Dienstleister, welcher die Verantwortung für die Durchführung der Parkservices trägt. Ich nehme weiter zur Kenntnis, dass TUI Cruises meinen Vor- und Nachnamen sowie meine E-Mail-Adresse an den Parkservice-Dienstleister weitergibt, damit dieser mir unter Verwendung dieser Kontaktdaten die Reservierungsbestätigung sendet."
              onChange={this.changedCheckbox}
              checked={bookingRequest.acceptedOperator}
            />
          </div>
        )}
        <div className="flex flex-col items-end">
          {this.isBookable && (
            <div>
              <Price price={bookable.priceText} unit={bookable.priceLabel} />
            </div>
          )}
          <div>{this.renderButton()}</div>
        </div>
      </div>
    );
  }
}
