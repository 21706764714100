// @flow
export const MASTERDATA_RELOAD_INTERVAL = 2 * 60 * 1000; // in ms
export const MASTERDATA_RELOAD_ERROR_LIMIT = 4;

export const MASTERDATA_STATUS_MANIFEST_NOT_AVAILABLE_ANYMORE = 'MANIFEST_NOT_AVAILABLE_ANYMORE';
export const MASTERDATA_STATUS_MANIFEST_NOT_AVAILABLE_ANYMORE_BUT_VIEWABLE =
  'MANIFEST_NOT_AVAILABLE_ANYMORE_BUT_VIEWABLE';
export const MASTERDATA_STATUS_MANIFEST_DATA_PRELIMINARY = 'MANIFEST_DATA_PRELIMINARY';

export const MASTERDATA_STATUS_PAYMENT_NOT_AVAILABLE_ANYMORE = 'PAYMENT_NOT_AVAILABLE_ANYMORE';
export const MASTERDATA_STATUS_PAYMENT_DATA_PRELIMINARY = 'PAYMENT_DATA_PRELIMINARY';
export const MASTERDATA_STATUS_PAYMENT_ROUTING_GROUP_CHECKED_IN = 'PAYMENT_ROUTING_GROUP_CHECKED_IN';

export const MASTERDATA_STATUS_CHECKIN_ALREADY_CHECKED_IN = 'CHECKIN_ALREADY_CHECKED_IN';
export const MASTERDATA_STATUS_CHECKIN_NOT_SCHENGEN_RESIDENT = 'CHECKIN_NOT_SCHENGEN_RESIDENT';
export const MASTERDATA_STATUS_CHECKIN_NOT_AVAILABLE_YET = 'CHECKIN_NOT_AVAILABLE_YET';
export const MASTERDATA_STATUS_CHECKIN_NOT_AVAILABLE_ANYMORE = 'CHECKIN_NOT_AVAILABLE_ANYMORE';
export const MASTERDATA_STATUS_CHECKIN_MANIFEST_NOT_COMPLETED = 'CHECKIN_MANIFEST_NOT_COMPLETED';
export const MASTERDATA_STATUS_CHECKIN_PAYMENT_NOT_COMPLETED = 'CHECKIN_PAYMENT_NOT_COMPLETED';
export const MASTERDATA_STATUS_CHECKIN_MANIFEST_AND_PAYMENT_NOT_COMPLETED =
  'CHECKIN_MANIFEST_AND_PAYMENT_NOT_COMPLETED';
export const MASTERDATA_STATUS_CHECKIN_DATA_PRELIMINARY = 'CHECKIN_DATA_PRELIMINARY';
export const MASTERDATA_STATUS_CHECKIN_TOO_CLOSE_TO_EMBARKATION = 'CHECKIN_TOO_CLOSE_TO_EMBARKATION';
export const MASTERDATA_STATUS_CHECKIN_MANIFEST_DATA_PRELIMINARY = 'CHECKIN_MANIFEST_DATA_PRELIMINARY';

export const ITINERARY_TYPE_SEA = 'sea';
export const ITINERARY_TENSE_PAST = 'past';
export const ITINERARY_TENSE_PRESENT = 'today';
export const ITINERARY_TENSE_FUTURE = 'future';

export const PACKAGE_SUB_TYPE_MEDIA = 'dvd';
export const PACKAGE_SUB_TYPE_INTERNET = 'internet';
export const PACKAGE_SUB_TYPE_NEWSPAPER = 'newspaper';
export const PACKAGE_SUB_TYPE_GOURMET = 'gourmetpackage';
export const PACKAGE_SUB_TYPE_FOTOSHOOT = 'fotoshooting';
export const PACKAGE_SUB_TYPE_HEALTHPACKAGE = 'healthpackage';
export const PACKAGE_SUB_TYPE_PARKING = 'parking';
export const PACKAGE_SUB_TYPE_PARKING_ADDON = 'parking_addon';

// TUICUNIT-1006 hack
export const PACKAGE_SUB_TYPE_SPA_INCLUSIVE = 'spa-inclusive';
export const PACKAGE_DISPLAY_TYPE_SPA_INCLUSIVE = 'SPA Balkonkabine | Inklusivleistung';

// TUICUNIT-1225
export const PACKAGE_SUB_TYPE_SHORE_EX_INCLUSIVE = 'excursion-inclusive';
export const PACKAGE_DISPLAY_TYPE_SHORE_EX_INCLUSIVE = '| Inklusivleistung';

export const PACKAGE_SUB_TYPE_MAPPING = {
  DVD: PACKAGE_SUB_TYPE_MEDIA,
  'DVD (pre cruise)': PACKAGE_SUB_TYPE_MEDIA,
  Reiseführer: PACKAGE_SUB_TYPE_MEDIA,
  'Reiseführer (pre cruise)': PACKAGE_SUB_TYPE_MEDIA,
  Reisemedien: PACKAGE_SUB_TYPE_MEDIA,
  'Reisemedien (pre cruise)': PACKAGE_SUB_TYPE_MEDIA,
  Internet: PACKAGE_SUB_TYPE_INTERNET,
  'Internet (pre cruise)': PACKAGE_SUB_TYPE_INTERNET,
  'Gourmet Paket': PACKAGE_SUB_TYPE_GOURMET,
  'Gourmet Paket (pre cruise)': PACKAGE_SUB_TYPE_GOURMET,
  Zeitungen: PACKAGE_SUB_TYPE_NEWSPAPER,
  'Zeitungen (pre cruise)': PACKAGE_SUB_TYPE_NEWSPAPER,
  Fotoshootings: PACKAGE_SUB_TYPE_FOTOSHOOT,
  'Fotoshootings (pre cruise)': PACKAGE_SUB_TYPE_FOTOSHOOT,
  'Ganz Schön Gesund Paket': PACKAGE_SUB_TYPE_HEALTHPACKAGE,
  'Ganz Schön Gesund Paket (pre cruise)': PACKAGE_SUB_TYPE_HEALTHPACKAGE,
  'Parking (pre cruise)': PACKAGE_SUB_TYPE_PARKING,
  'Parking (pre cruise - add. service)': PACKAGE_SUB_TYPE_PARKING_ADDON,
  'Parking-Link (pre cruise)': PACKAGE_SUB_TYPE_PARKING, // should never be needed
  [PACKAGE_DISPLAY_TYPE_SPA_INCLUSIVE]: PACKAGE_SUB_TYPE_SPA_INCLUSIVE, // should never be needed
};

export const PACKAGE_DELIVER_ON_FIRST_DAY = 'firstDay';
export const PACKAGE_DELIVER_ON_LAST_DAY = 'lastDay';

export const CHECKBOX_GROUP_ANY = '_ANY_';
export const DIFFICULTY_ANY = '_ANY_';
export const DIFFICULTIES = ['EXTRA_EASY', 'EASY', 'MODERATE', 'CHALLENGING', 'SPORTIVE'];

export const PAYMENT_OPTION_SEPA = 'SEPA';
export const PAYMENT_OPTION_VISA = 'VISA';
export const PAYMENT_OPTION_MASTER_CARD = 'MASTERCARD';
export const PAYMENT_OPTION_MASTER_CARD_MC = 'MC';
export const PAYMENT_OPTION_AMERICAN_EXPRESS = 'AMEX';
export const PAYMENT_OPTION_CASH = 'Cash';
export const PAYMENT_TYPE_CREDIT_CARD = 'CreditCard';
export const INCLUSIVE_INFO = {
  'Early boarding (pre cruise)': {
    freeForSuites: {
      headline: 'Kostenloser Early Check-In für Suiten und Junior Suiten Gäste',
      text: 'Unseren Gästen der Suiten und Junior Suiten steht der Early Check-in kostenfrei zur Verfügung. So haben Sie die Möglichkeit schon vor der kommunizierten Anreisezeit an Bord zu gehen und entspannt in Ihren Urlaub zu starten. Bitte beachten Sie, dass der Early Check-In nur die Nutzung der öffentlichen Bereiche einschließt. Die frühestmögliche Check-in Zeit entnehmen Sie bitte Ihren Reiseunterlagen. Die Kabinen stehen Ihnen spätestens ab 15:00 Uhr zur Verfügung.',
    },
    freeForVips: {
      headline: 'Inkludierter Early Check-in',
      text: 'Für Sie ist der Early Check-in bereits inkludiert. So haben Sie die Möglichkeit schon vor der kommunizierten Anreisezeit an Bord zu gehen und entspannt in Ihren Urlaub zu starten. Bitte beachten Sie, dass der Early Check-in nur die Nutzung der öffentlichen Bereiche einschließt. Die frühestmögliche Check-in Zeit entnehmen Sie bitte Ihren Reiseunterlagen. Die Kabinen stehen Ihnen ab spätestens 15:00 Uhr zur Verfügung.',
    },
  },
  Tagesverlängerung: {
    freeForSuites: {
      headline: 'Kostenlose Tagesverlängerung für Suiten und Junior Suiten Gäste',
      text: 'Unseren Gästen der Suiten und Junior Suiten steht die Tagesverlängerung kostenfrei zur Verfügung. So haben Sie die Möglichkeit auch nach dem offiziellen Reiseende um 11:00 Uhr an Bord zu bleiben und entspannt Ihren Urlaub ausklingen zu lassen. Bitte beachten Sie, dass die Tagesverlängerung nur die Nutzung der öffentlichen Bereiche einschließt und das Schiff spätestens um 17 Uhr verlassen werden muss. Die Kabinen stehen Ihnen am Abreisetag bis 09:00 Uhr zur Verfügung.',
    },
  },
  'Tagesverlängerung (pre cruise)': {
    freeForSuites: {
      headline: 'Kostenlose Tagesverlängerung für Suiten und Junior Suiten Gäste',
      text: 'Unseren Gästen der Suiten und Junior Suiten steht die Tagesverlängerung kostenfrei zur Verfügung. So haben Sie die Möglichkeit auch nach dem offiziellen Reiseende um 11:00 Uhr an Bord zu bleiben und entspannt Ihren Urlaub ausklingen zu lassen. Bitte beachten Sie, dass die Tagesverlängerung nur die Nutzung der öffentlichen Bereiche einschließt und das Schiff spätestens um 17 Uhr verlassen werden muss. Die Kabinen stehen Ihnen am Abreisetag bis 09:00 Uhr zur Verfügung.',
    },
  },
};

export const USER_PIN_LENGTH = 4;

// questionnaire Stats: TUICUNIT-2221
// TUICUNIT-2681: more than one Not OK state, for whatever reason
export const SURVEY_NOT_AVAILABLE = 'SURVEY_NOT_AVAILABLE';
export const SURVEY_NOT_YET_OPENED = 'SURVEY_AVAILABLE_BUT_NOT_YET_OPENED';
export const SURVEY_MPI_OPEN = 'SURVEY_AVAILABLE_OPEN_MPI_INCOMPLETE';
export const SURVEY_MPI_NOT_OK = 'SURVEY_AVAILABLE_OPEN_MPI_COMPLETE_NOT_OK';
export const SURVEY_MPI_NOT_OK_L1 = 'SURVEY_AVAILABLE_OPEN_MPI_COMPLETE_NOT_OK_LEVEL_1';
export const SURVEY_MPI_NOT_OK_L2 = 'SURVEY_AVAILABLE_OPEN_MPI_COMPLETE_NOT_OK_LEVEL_2';
export const SURVEY_MPI_OK = 'SURVEY_AVAILABLE_OPEN_MPI_COMPLETE_OK';
export const SURVEY_MPI_CONFIRMATION_REQUIRED = 'SURVEY_AVAILABLE_OPEN_MPI_CONFIRMATION_REQUIRED';
export const SURVEY_CLOSED = 'SURVEY_AVAILABLE_BUT_CLOSED';
// TUICUNIT-2681: MultiChoice CheckBoxen
export const SURVEY_SINGLECHOICE = 'singleChoice';
export const SURVEY_MULTICHOICE = 'multiChoice';
