var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import CheckIcon from '../../../assets/svg/redesign/check.svg';
import MinusIcon from '../../../assets/svg/redesign/minus.svg';
var checkboxVariants = tv({
    slots: {
        root: 'flex gap-2',
        inputWrapper: 'inline-block relative shrink-0 mt-1',
        input: [
            'peer appearance-none outline outline-1 outline-ocean-blue-100',
            'absolute inset-0 rounded-sm',
            'checked:bg-ocean-blue-100',
        ],
        checked: [
            'hidden absolute inset-0 pointer-events-none select-none',
            'peer-checked:inline-block text-high-emphasis',
        ],
        indeterminate: [
            'hidden absolute inset-0 pointer-events-none select-none',
            'peer-indeterminate:inline-block text-high-emphasis',
        ],
        labelWrapper: 'flex flex-col',
        title: 'text-md font-semibold',
        description: 'text-sm',
        error: 'text-red-500 text-sm',
    },
    variants: {
        size: {
            md: {
                inputWrapper: 'h-5 w-5',
            },
            lg: {
                inputWrapper: 'h-7 w-7',
            },
        },
        hasError: {
            true: {
                input: 'outline-red-500 outline-2',
                label: 'text-red-500',
                description: 'text-red-500',
            },
        },
    },
    defaultVariants: {
        size: 'md',
    },
});
export var Checkbox = forwardRef(function (_a, ref) {
    var size = _a.size, title = _a.title, description = _a.description, className = _a.className, classNames = _a.classNames, error = _a.error, props = __rest(_a, ["size", "title", "description", "className", "classNames", "error"]);
    var classes = checkboxVariants({ size: size, hasError: !!error });
    return (React.createElement("label", { className: classes.root({ className: classNames === null || classNames === void 0 ? void 0 : classNames.root }) },
        React.createElement("span", { className: classes.inputWrapper() },
            React.createElement("input", __assign({}, props, { ref: ref, type: "checkbox", className: classes.input({ className: className }) })),
            React.createElement("span", { className: classes.checked() },
                React.createElement(CheckIcon, null)),
            React.createElement("span", { className: classes.indeterminate() },
                React.createElement(MinusIcon, null))),
        React.createElement("div", { className: classes.labelWrapper() },
            title && (React.createElement("p", { className: classes.title(), "data-tailwind": true }, title)),
            description && (React.createElement("p", { className: classes.description(), "data-tailwind": true }, description)),
            error && (React.createElement("p", { className: classes.error(), "data-tailwind": true }, error)))));
});
