// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import Headline from '../../Headline';
import LoadingIndicator from '../../LoadingIndicator';

import Tiles from '../../BookingDetails/Tiles';
import type MasterStore from '../../../stores/MasterStore';
import type TilesStore from '../../../stores/TilesStore';
import type VipOptionStore from '../../../stores/VipOptionStore';
import BreadcrumbLink from '../../breadcrumbs/BreadcrumbLink';
import track from '../../../tracking';
// TUICUNIT-3146: hide VIP1 Coupon
// import VipCouponView from './VipCoupon';
import FlightPreferenceTeaser from '../../../components/BookingDetails/FlightPreference/Teaser';
import { SURVEY_CLOSED } from '../../../config/constants';

type Props = {
  masterStore: MasterStore,
  tilesStore: TilesStore,
  vipOptionStore: VipOptionStore,
  params: { tariffId: string },
};

@inject('vipOptionStore')
@observer
class VipTariffBreadcrumb extends Component<{
  vipOptionStore: VipOptionStore,
}> {
  componentDidMount() {
    const { vipOptionStore, params } = this.props;
    vipOptionStore.fetch(params.tariffId);
  }

  render() {
    const { vipOptionStore } = this.props;
    const { vipOption } = vipOptionStore;
    if (!vipOption) return null;

    return <BreadcrumbLink {...this.props} text={vipOption.headline} />;
  }
}

@inject('masterStore', 'tilesStore', 'vipOptionStore')
@observer
export default class VipTariff extends Component<Props> {
  static breadcrumb = VipTariffBreadcrumb;

  componentDidMount() {
    const { tilesStore, vipOptionStore, params } = this.props;
    tilesStore.fetchAll();
    // TUICUNIT-2706: force flightPreferences state
    vipOptionStore.fetch(params.tariffId);
    this.trackPageView();
  }

  trackPageView = () => {
    track.pageView(window.location.pathname, ['buchung-reiseunterlagen', 'vip-tarif'], 'sub-category');
  };

  @autobind
  handlePrint() {
    window.print();
  }

  render() {
    const { masterStore, tilesStore, vipOptionStore } = this.props;
    const { masterData } = masterStore;
    const { tiles } = tilesStore;
    const { vipOption } = vipOptionStore;
    const { headline, subHeadline, description, flightPreferences } = vipOption || {};

    if (vipOptionStore.isLoading || tilesStore.isLoading) {
      return <LoadingIndicator />;
    }

    return (
      <div className="page page-vip-tariff">
        {headline && <Headline className="l-mod less-margin" title={headline} />}
        <p dangerouslySetInnerHTML={{ __html: subHeadline }} />
        <div className="description" dangerouslySetInnerHTML={{ __html: description }} />

        <div className="info-link">
          <span>Mehr über die </span>
          <a
            className="underline"
            rel="noopener noreferrer"
            href="https://www.meinschiff.com/preismodelle/vip-tarifoptionen"
            target="_blank"
          >
            VIP-Tarifoptionen
          </a>
        </div>

        {/* TUICUNIT-3146: hide VIP1 Coupon */}
        {/* masterData.requestorHasVipOption1 ? <VipCouponView /> : null */}

        {masterData.requestorHasVipOption1 &&
        !vipOptionStore.isLoading &&
        flightPreferences &&
        flightPreferences.status !== SURVEY_CLOSED ? (
          <FlightPreferenceTeaser meta={flightPreferences} />
        ) : null}

        <div className="clear spacer" />
        <Tiles tiles={tiles} masterData={masterData} />
      </div>
    );
  }
}
