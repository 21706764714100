// @flow
import {
  PACKAGE_SUB_TYPE_PARKING,
  PACKAGE_SUB_TYPE_PARKING_ADDON,
  PACKAGE_SUB_TYPE_MAPPING,
} from '../config/constants';
import Package from './Package';

export default class ParkingPackage extends Package {
  subline: string;
  externalUrl: string;
  category: string;
  addOn: boolean;

  constructor(data, masterStore) {
    super(data, masterStore);

    if (!data.addOn && data.category) {
      this.addOn = PACKAGE_SUB_TYPE_MAPPING[data.category] === PACKAGE_SUB_TYPE_PARKING_ADDON;
    }
  }

  get packageType(): string {
    return this.addOn ? PACKAGE_SUB_TYPE_PARKING_ADDON : PACKAGE_SUB_TYPE_PARKING;
  }

  get bookingName(): string {
    return 'Parkservice';
  }

  get subLine(): string {
    return this.subline;
  }

  get description(): string {
    return this.text;
  }

  get urlExtern(): string {
    return this.externalUrl;
  }
}
