// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import LoadingIndicator from '../../components/LoadingIndicator';
import { ParkingAddonTile } from '../parking/ParkingAddonTile';
import { ParkingPackageTile } from '../parking/ParkingPackageTile';
import type PackageStore from '../../stores/PackageStore';
import type TypeStore from '../../stores/OfferOrPackageTypeStore';
import type Package from '../../models/Package';
import type { TrackingExcursion } from '../../types/tracking';
import { tealiumDateFromDateObject, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';
import ParkingPackage from '../../models/ParkingPackage';
import WarningCircleIcon from '../../../assets/svg/inline/warning-circle.inline.svg';

type Props = {
  packageStore: PackageStore,
};

@inject('masterStore', 'packageStore', 'breadcrumbRouterStore')
@observer
export default class PageTravelMedia extends Component<Props> {
  static breadcrumb = 'Parkservice';

  componentDidMount() {
    this.store.fetchList();
    setTimeout(() => {
      this.trackPageView();
    }, 0);
  }

  trackPageView = () => {
    tracking.pageView(window.location.pathname, ['parkservice'], 'category');
  };

  @computed
  get store(): TypeStore {
    const { packageStore } = this.props;
    return packageStore.storeForType('parking');
  }

  badgeFor(pkg: Package) {
    if (!pkg.isBookable) return pkg.bookingStatus;
    if (pkg.isBooked) return 'Bereits angefragt';
    return null;
  }

  triggered = false;

  @computed
  get packages(): ParkingPackage[] {
    return this.store.items.filter((item) => !item.addOn);
  }

  @computed
  get addOns(): ParkingPackage[] {
    return this.store.items.filter((item) => !!item.addOn);
  }

  @computed
  get isOnlyAddOnBooked(): boolean {
    const packagesBooked = this.packages.some((pkg) => pkg.isBooked);
    const addOnsBooked = this.addOns.some((addOn) => addOn.isBooked);

    return !packagesBooked && addOnsBooked;
  }

  render() {
    if (this.store.isLoading) return <LoadingIndicator />;

    if (this.triggered === false && this.store.items) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = this.store.items.map((item: Package) => ({
        code: item.code,
        name: item.name,
        discount: '0.00',
        startDate: tealiumDateFromDateObject(new Date()),
        value: tealiumValue(String(item.price)),
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <h2 className="text-h3 md:text-h2" data-tailwind>
            Parkplatz auswählen
          </h2>
          <div className="grid gap-4 grid-cols-1 md:grid-cols-3 grid-flow-row md:auto-rows-fr">
            {this.packages.map((pkg, index) => (
              <div
                key={pkg.id}
                onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
                className="flex"
              >
                <ParkingPackageTile
                  title={pkg.title}
                  description={pkg.subLine}
                  link={pkg.urlExtern || `/parkservice/${pkg.typeId}`}
                  newPage={!!pkg.urlExtern}
                  price={pkg.price}
                  priceLabel={pkg.priceLabel}
                  isBooked={pkg.isBooked}
                  prelimenary={pkg.prelimenary}
                  imageSrc={pkg.imageSrc}
                  unavailableBadge={this.badgeFor(pkg)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex items-end gap-2">
            <h2 className="text-h3 md:text-h2">Zusatzleistungen</h2>
            <p className="text-md">nur mit Parkplatz buchbar</p>
          </div>
          {this.isOnlyAddOnBooked && (
            <div className="flex p-6 gap-2 bg-warning-20 text-warning-120 border border-solid border-warning-120 rounded-md">
              <div className="mt-0.5">
                <WarningCircleIcon className="size-6" />
              </div>
              <div className="flex flex-col">
                <h3 className="text-md font-bold m-0">Kein Parkplatz gebucht</h3>
                <p className="text-sm m-0">Sie haben eine Zusatzleistungen ohne Parkplatz gebucht</p>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4">
            {this.addOns.map((addon, index) => (
              <div
                key={addon.id}
                onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
              >
                <ParkingAddonTile
                  isBooked={addon.isBooked}
                  unavailableBadge={this.badgeFor(addon)}
                  prelimenary={addon.prelimenary}
                  title={addon.title}
                  description={addon.subLine}
                  link={addon.urlExtern || `/parkservice/${addon.typeId}`}
                  price={addon.price}
                  newPage={!!addon.urlExtern}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
