import React from 'react';
import Badge from '../Badge';
import { Link } from 'react-router';
import Price from '../Price';
import classNames from 'classnames';
export var ParkingPackageTile = function (_a) {
    var title = _a.title, description = _a.description, link = _a.link, newPage = _a.newPage, imageSrc = _a.imageSrc, price = _a.price, isBooked = _a.isBooked, prelimenary = _a.prelimenary, isBookedBadge = _a.isBookedBadge, unavailableBadge = _a.unavailableBadge;
    var badge = isBooked ? (React.createElement(Badge, { booked: true }, prelimenary ? 'in Bearbeitung' : isBookedBadge || 'Reserviert')) : unavailableBadge ? (React.createElement(Badge, { unavailable: true }, unavailableBadge)) : null;
    return (React.createElement(Link, { target: newPage ? '_blank' : undefined, to: link, className: classNames('relative flex md:flex-col gap-2 bg-white rounded-lg p-2', badge && 'md:mt-0 mt-4') },
        React.createElement("div", { className: "w-[20%] md:w-auto shrink-0" },
            React.createElement("img", { src: imageSrc, alt: "parking", className: "rounded aspect-video object-cover" })),
        React.createElement("div", { className: "flex flex-col gap-4 grow" },
            React.createElement("h2", { className: "m-0" }, title),
            React.createElement("p", null, description)),
        React.createElement("div", { className: "flex flex-col md:flex-row justify-end" },
            React.createElement(Price, { price: price, className: "m-0 shrink" })),
        badge));
};
