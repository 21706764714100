import React from 'react';
import Link from 'react-router/lib/Link';
import Badge from '../Badge';
import Price from '../Price';
import classNames from 'classnames';
export var ParkingAddonTile = function (_a) {
    var title = _a.title, subTitle = _a.subTitle, description = _a.description, link = _a.link, newPage = _a.newPage, isBooked = _a.isBooked, prelimenary = _a.prelimenary, isBookedBadge = _a.isBookedBadge, unavailableBadge = _a.unavailableBadge, price = _a.price;
    var badge = isBooked ? (React.createElement(Badge, { booked: true }, prelimenary ? 'in Bearbeitung' : isBookedBadge || 'Reserviert')) : unavailableBadge ? (React.createElement(Badge, { unavailable: true }, unavailableBadge)) : null;
    return (React.createElement(Link, { target: newPage ? '_blank' : undefined, to: link, className: classNames('flex relative bg-white rounded-lg items-center py-2 md:py-10 px-2', badge && 'md:mt-0 mt-4') },
        React.createElement("div", { className: "flex flex-col w-[30%] pl-2 text-center" },
            React.createElement("h3", { className: "m-0 underline" }, title),
            subTitle && React.createElement("span", null, subTitle)),
        React.createElement("div", { className: "grow text-center" },
            React.createElement("span", null, description)),
        React.createElement("div", { className: "absolute right-2 bottom-2" },
            React.createElement(Price, { price: price, className: "m-0" })),
        badge));
};
