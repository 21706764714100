// @flow
import React, { Component } from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import preload from '../decorators/preload';
import LoadingIndicator from '../../LoadingIndicator';
import ErrorMessage from '../../../components/form/ErrorMessage';
import LinkAsButton from '../../../components/LinkAsButton';
import storage from '../../../utils/storage';

import type MasterStore from '../../../stores/MasterStore';
import type PartyMember from '../../../models/PartyMember';

import { routerShape } from 'react-router/lib/PropTypes';
import pinStorage from '../../../utils/pinStorage';
import { pinRedirectUrl } from '../../../utils/pinRedirectUrl';
import { isIE } from '../../../utils/browser';

import type DigitalHealthStore from '../../../stores/DigitalHealthStore';
import SurveyView from '../../Manifest/DigitalHealth/Survey';
import {
  SURVEY_MPI_OK,
  SURVEY_MPI_NOT_OK,
  SURVEY_MPI_CONFIRMATION_REQUIRED,
  SURVEY_CLOSED,
} from '../../../config/constants';
import track from '../../../tracking';
import { Button } from '../../redesign/Button';
import { Link } from 'react-router';

type Props = {
  masterStore: MasterStore,
  digitalHealthStore: DigitalHealthStore,
  params: Object,
  route: { isCheckinSubRoute: boolean },
};

@inject('masterStore', 'digitalHealthStore')
@preload({ masterStore: 'MasterStore' })
@observer
export default class PageManifestDigitalHealth extends Component<Props> {
  static breadcrumb = 'Gesundheitsfragebogen';

  static contextTypes = {
    router: routerShape,
  };

  componentDidMount() {
    if (!isIE()) {
      window.addEventListener('storage', this.storageChanged);
    }
    if (this.currentMember) {
      pinStorage.removeUrl(this.currentMember.mpi); // TUICUNIT-911
    }
    const { digitalHealthStore } = this.props;
    digitalHealthStore.fetch(this.currentMember).then(() => {
      this.checkPin();
    });

    let trackingTitles = ['schiffsmanifest', 'gesundheitsfragebogen', 'person-' + this.props.params.memberIndex];
    // Page View Tracking
    track.pageView(
      window.location.pathname,
      this.props.route.isCheckinSubRoute ? ['online-check-in', ...trackingTitles] : trackingTitles,
      'sub-category'
    );
  }

  componentWillUnmount() {
    if (!isIE()) {
      window.removeEventListener('storage', this.storageChanged);
    }
  }

  @autobind
  storageChanged() {
    if (!isIE()) {
      this.checkPin();
    }
  }

  checkPin() {
    const error = pinStorage.getApiPinError();
    if (error) {
      this.context.router.replace(pinRedirectUrl(pinStorage.getUrl(error.mpi) || '/'));
      return;
    }
  }

  @computed
  get currentMember(): ?PartyMember {
    const { params, masterStore } = this.props;
    return masterStore.masterData.getPartyMemberByIndex(params.memberIndex);
  }

  render() {
    // const isInAppView = this.props.masterStore.isInAppView;
    const { digitalHealthStore } = this.props;
    const isCheckinSubRoute = this.props.route.isCheckinSubRoute;

    const currentMember = this.currentMember;
    if (!currentMember) {
      return (
        <div className="page survey error">
          <ErrorMessage error={digitalHealthStore.error.text} />
        </div>
      );
    }

    if (digitalHealthStore.isLoading) return <LoadingIndicator />;

    if (digitalHealthStore.hasError) {
      return (
        <div className="page survey error">
          <ErrorMessage error={digitalHealthStore.error.text} />
        </div>
      );
    }

    if (digitalHealthStore.getQuestions && !digitalHealthStore.getQuestions[0]) {
      return (
        <div className="page survey error">
          <ErrorMessage error={digitalHealthStore.error.text} />
        </div>
      );
    }

    let viewResult = false;
    if (
      currentMember.digitalHealthState === SURVEY_MPI_OK ||
      currentMember.digitalHealthState === SURVEY_MPI_NOT_OK ||
      currentMember.digitalHealthState === SURVEY_MPI_CONFIRMATION_REQUIRED ||
      currentMember.digitalHealthState === SURVEY_CLOSED
    ) {
      viewResult = true;
    }

    return (
      <div className="page survey health">
        <SurveyView
          currentMember={currentMember}
          digitalHealthStore={digitalHealthStore}
          inProgress={digitalHealthStore.inProgress}
          viewResult={viewResult}
          lang={storage.get('language') || 'de'}
        />
        <div className="l-row">
          <div className="l-col double l-right">
            {isCheckinSubRoute ? (
              <Button as={Link} variant="dark" size="md" to="/checkin">
                Zur Check-in Übersicht
              </Button>
            ) : (
              <Button as={Link} variant="dark" size="md" to="/manifest">
                Zur Manifest-Übersicht
              </Button>
            )}
          </div>
        </div>
        <br />
      </div>
    );
  }
}
