// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Headline from '../Headline';
import ToggleInfoBox from '../ToggleInfoBox';
import ExcursionItineraryList from '../Excursion/ItineraryList';
import Brochure from '../Excursion/Brochure';
import track from '../../tracking';

import type MasterStore from '../../stores/MasterStore';

type Props = {
  masterStore: MasterStore,
};

const operatorText = {
  headline: 'Wichtiger Hinweis zur Vermittlerrolle von TUI Cruises.',
  text: 'Die hier aufgelisteten Landausflüge werden von TUI Cruises zum Teil vermittelt und zum Teil selbst veranstaltet. Insbesondere Bikeausflüge werden von TUI Cruises organisiert und durchgeführt. Alle weiteren Ausflüge werden von TUI Cruises vermittelt und von örtlichen Veranstaltern durchgeführt. Die Durchführung, Verantwortung und Haftung für diese Ausflüge liegt allein in den Händen des jeweiligen örtlichen Veranstalters, der zugleich Ihr Vertragspartner ist.',
};

@inject('masterStore', 'breadcrumbRouterStore')
@observer
export default class PageExcursion extends Component<Props> {
  static breadcrumb = 'Landausflüge';

  componentDidMount() {
    this.props.masterStore.reloadMasterData();
    this.props.masterStore.reloadItinerary();
    setTimeout(() => {
      this.trackPageView();
    }, 0);
  }

  trackPageView = () => {
    const { breadcrumbRouterStore, location } = this.props;
    const titles = breadcrumbRouterStore.getWithoutFirst;

    if (titles.length) {
      track.pageView(location.pathname, titles, 'search');
    }
  };

  render() {
    const { masterData, itinerary } = this.props.masterStore;

    return (
      <div className="page page-excursions">
        <div className="l-row group">
          <div className="l-col double">
            <ToggleInfoBox
              moreLink="Mehr Informationen"
              lessLink="Weniger Informationen"
              teaserContent={
                <div>
                  <h2>{operatorText.headline}</h2>
                  <p className="preview">{operatorText.text}</p>
                </div>
              }
            >
              <h2>{operatorText.headline}</h2>
              <p>{operatorText.text}</p>
            </ToggleInfoBox>

            <h2>Bitte wählen Sie einen Reisetag aus:</h2>
          </div>
        </div>

        <div className="l-row group">
          <div className="l-col double">
            <ExcursionItineraryList itinerary={itinerary} masterData={masterData} />
          </div>
          <div className="l-col right l-mod-sub no-print">
            <Brochure masterData={masterData} />
          </div>
        </div>
      </div>
    );
  }
}
