// @flow

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { inject, observer } from 'mobx-react';

import BookingRequest from '../../models/BookingRequest/ParkingBookingRequest';

import preload from './decorators/preload';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import WithBookingConfirmation from '../BookingConfirmation/WithBookingConfirmation';
import ParkingBookingAssistant from '../BookingAssistant/ParkingBookingAssistant';

import type MasterStore from '../../stores/MasterStore';
import type BookingUiStore from '../../stores/BookingUiStore';
import type ParkingPackage from '../../models/ParkingPackage';
import type Booking from '../../models/Booking';
import { tealiumDateFromString, tealiumIdFromString, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';
import { computed } from 'mobx';
import LoadingIndicator from '../LoadingIndicator';
import { Button } from '../redesign/Button';

@inject('masterStore')
@preload({ packageDetails: 'Package' }, false)
@observer
class PackageDetailsBreadcrumb extends Component<{
  masterStore: MasterStore,
  packageDetails: ParkingPackage,
}> {
  render() {
    const { packageDetails, masterStore } = this.props;
    return (
      <BreadcrumbLink
        {...this.props}
        text={packageDetails.headline}
        bookingRequest={
          new BookingRequest(masterStore.masterData.travelParty, packageDetails, masterStore.packagesOfUser)
        }
      />
    );
  }
}

type Props = {
  masterStore: MasterStore,
  bookingUiStore: BookingUiStore,
  packageDetails: ParkingPackage,
};

@inject('masterStore', 'bookingUiStore', 'breadcrumbRouterStore', 'packageStore')
@preload({ packageDetails: 'Parking' })
@observer
export default class PageParkingDetails extends Component<Props> {
  static breadcrumb = PackageDetailsBreadcrumb;

  bookingRequest: BookingRequest;

  constructor(props: Props) {
    super(props);
    const { masterStore, packageDetails } = this.props;

    this.bookingRequest = new BookingRequest(
      masterStore.masterData.travelParty,
      packageDetails,
      masterStore.packagesOfUser
    );
  }

  @computed
  get parkingStore(): TypeStore {
    const { packageStore } = this.props;
    return packageStore.storeForType('parking');
  }

  tracked = false;

  componentDidMount(): * {
    this.parkingStore.fetchList();
    this.triggerTracking();
  }

  triggerTracking() {
    if (this.tracked === false) {
      this.tracked = true;
      tracking.pageView(window.location.pathname, ['parkservice', this.bookingRequest.bookable.name], 'product');
      tracking.viewItem(this.props.breadcrumbRouterStore, {
        category: this.bookingRequest.bookable.analyticsCategory,
        categoryId: tealiumIdFromString(this.bookingRequest.bookable.analyticsCategory),
        discount: '0.00',
        code: this.bookingRequest.bookable.code,
        name: this.bookingRequest.bookable.name,
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
        quantity: '1',
        startDate: tealiumDateFromString(this.bookingRequest.bookable.startDate),
        value: tealiumValue(String(this.bookingRequest.total)),
      });
    }
  }

  @autobind
  handleBookingCancelClick(pkg: Booking) {
    this.bookingRequest.cancelBooking(pkg);
    this.props.bookingUiStore.openBookingDialog();
  }

  @computed
  get isAddOnBookable(): boolean | null {
    const { packageDetails } = this.props;

    const item = this.parkingStore.items.find((item) => item.typeId === packageDetails.typeId);
    if (!item?.addOn) return null;

    return this.parkingStore.items.filter((pkg) => !pkg.addOn).some((pkg) => pkg.isBooked) ?? false;
  }

  renderBookingList() {
    const { masterStore } = this.props;
    const bookings = this.bookingRequest.bookedParkingPackages;

    return (
      <div className="flex flex-col gap-4">
        <h3 className="text-h3 font-bold m-0">Bereits reservierte Pakete:</h3>
        <div className="flex flex-col gap-4">
          {bookings.map((booking) => (
            <div
              key={booking.typeId}
              className="bg-white flex flex-col md:flex-row gap-4 justify-between items-center p-6 rounded"
            >
              <div className="flex flex-col text-center md:text-start">
                <p className="text-h3 md:text-h2 m-0">
                  {booking.displayName}
                  {booking.prelimenary ? ' (in Bearbeitung)' : ''}
                </p>
                <p className="text-md font-bold m-0">{masterStore.user.displayName}</p>
              </div>
              <div>
                <Button onClick={() => this.handleBookingCancelClick(booking)}>Reservierung stornieren</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { packageDetails, bookingUiStore } = this.props;

    if (this.parkingStore.isLoading || !packageDetails) return <LoadingIndicator />;

    return (
      <WithBookingConfirmation bookingRequest={this.bookingRequest}>
        <div className="flex flex-col gap-10">
          <div>
            <img src={packageDetails.imageSrc} alt="parking" className="rounded object-cover aspect-video" />
            <p className="text-md m-0">{packageDetails.description}</p>
          </div>
          {this.renderBookingList()}
          <ParkingBookingAssistant
            bookable={packageDetails}
            bookingRequest={this.bookingRequest}
            onBooking={() => {
              tracking.addToCart('1', this.bookingRequest.total);
              bookingUiStore.openBookingDialog();
            }}
            addonBookable={this.isAddOnBookable}
          />
        </div>
      </WithBookingConfirmation>
    );
  }
}
