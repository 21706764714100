// @flow
import {
  PACKAGE_SUB_TYPE_MAPPING,
  PACKAGE_SUB_TYPE_PARKING,
  PACKAGE_SUB_TYPE_PARKING_ADDON,
} from '../config/constants';

import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';
import { prepareText } from '../utils/prepareText';

import type User from '../models/User';
import type MasterData from '../models/MasterData';
import { isLocal, mockAll } from './utils';

const convertSecondsToMs = (seconds) => seconds * 1000;
const mock = false;
const getUrl = (): string =>
  (mock || mockAll) && isLocal
    ? `/mock/masterdata/TUICUNIT-3368_GroupCheckin.json`
    : `/api/land/masterdata?disableSurveyData=true`;

const mockSurvey = false;
const getSurveyUrl = () =>
  (mockSurvey || mockAll) && isLocal ? `/mock/surveyData/TUICUNIT-3474.json` : `/api/land/surveydata`;

const fixBookingsIds = (bookings) =>
  bookings.map((booking) => {
    let typeId = parseInt(booking.typeId, 10);
    return {
      ...booking,
      // the typeId is a string for venues, but for real excursions it is an integer
      typeId: isNaN(typeId) ? booking.typeId : typeId,
    };
  });

// camelCasing everything will make the bookings keys look wrong
const fixBookingsKeys = (bookings) =>
  Object.keys(bookings).reduce((memo, key) => {
    memo[`${key.substr(0, 4)}-${key.substr(4, 2)}-${key.substr(6, 2)}`] = fixBookingsIds(bookings[key]);
    return memo;
  }, {});

const handlePartyMemberData = (travelParty) =>
  travelParty.map((member) => ({
    ...member,
    age: member.age === null ? 25 : member.age,
    // questionnaire: member.questionnaire ? partyMemberQuestionnaireData(member.questionnaire) : null,
  }));

/* TUICUNIT-3368: Check-in for Groups (@_@) */
const groupBookingData = (groupBooking) => {
  groupBooking.status = groupBooking.status || false;
  groupBooking.content = groupBooking.content ? prepareText(groupBooking.content) : '';
  return groupBooking;
};

/*
// TUICUNIT-2681: more than one Not OK state, for whatever reason
const partyMemberQuestionnaireData = questionnaire => {
  if (questionnaire.status) {
    if (
      questionnaire.status === SURVEY_MPI_NOT_OK ||
      questionnaire.status === SURVEY_MPI_NOT_OK_L1 ||
      questionnaire.status === SURVEY_MPI_NOT_OK_L2
    ) {
      questionnaire.status = SURVEY_MPI_NOT_OK;
    }
  }
  return questionnaire;
};
*/

const checkPackages = (packages) => packages.map((pkg) => ({ ...pkg, subType: PACKAGE_SUB_TYPE_MAPPING[pkg.subType] }));

const transform = (response: Object) => {
  if (response.status && response.status !== 200) {
    return null;
  }

  const hash = camelCaseKeys(response, true);

  return {
    ...hash,
    travelParty: handlePartyMemberData(hash.travelparty),
    bookings: fixBookingsKeys(hash.bookings),
    packages: checkPackages(hash.packages),
    startOfJourney: convertSecondsToMs(hash.startOfJourney),
    creationDate: convertSecondsToMs(hash.creationDate),
    embarkDate: convertSecondsToMs(hash.ed),
    debarkDate: convertSecondsToMs(hash.dd),
    groupBooking: hash.groupBooking ? groupBookingData(hash.groupBooking) : groupBookingData({}),
  };
};

export const sanitizers = {
  get: transform,
};

export default sanitized(
  {
    get: (user) => fetchForUser(user, getUrl()),
    getSurvey: (user) => fetchForUser(user, getSurveyUrl()),
  },
  sanitizers
);

export type ApiType = {
  get: (User) => Promise<MasterData>,
  getSurvey: (User) => Promise<MasterData>,
};
