// @flow
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { obfuscateIban } from '../../../utils/payment';

import type PaymentStoreRequest from '../../../models/PaymentStoreRequest';
import { Input } from '../../redesign/Input';
import { Checkbox } from '../../redesign/Checkbox';
import HintIcon from '../../../../assets/svg/inline/ic-info.inline.svg';
import { Text } from '../../redesign/Text';

type Props = {
  storeRequest: PaymentStoreRequest,
  disabled: ?boolean,
};

@observer
export default class PaymentSepaOption extends Component<Props> {
  data = {};
  @observable notAgreedError = false;

  @action
  validateForm() {
    this.notAgreedError = false;
    if (!this.props.storeRequest.sepaAgreement) {
      this.notAgreedError = true;
      return false;
    }
    return true;
  }

  handleInputChange(key: string, value: string) {
    this.props.storeRequest.saveRequestData(key, value);
  }

  @autobind
  handleAgreementChange(value: boolean) {
    this.props.storeRequest.toggleSepaAgreement(value);
  }

  render() {
    const { storeRequest, disabled } = this.props;
    const { paymentData, error: storeError } = storeRequest;

    return (
      <div className="flex flex-col gap-8">
        <div className="flex gap-2">
          <HintIcon className="size-4 shrink-0 mt-1" />
          <Text type="sm">
            Wir bitten um Ihr Verständnis, dass aus technischen Gründen keine Diners Cards sowie ausländische EC-Karten
            akzeptiert werden können. Ein Zahlungstransaktionsentgelt fällt für die Begleichung der Bordabrechnung nicht
            an.
          </Text>
        </div>
        {this.error ? <p className="error-message">{this.error}</p> : null}
        <div className="flex *:w-[50%]">
          <Input
            disabled={disabled}
            label="IBAN"
            error={storeError}
            mandatory
            name="iban"
            value={paymentData && obfuscateIban(paymentData.iban)}
            onChange={(value) => this.handleInputChange('iban', value)}
          />
        </div>
        <Checkbox
          checked={storeRequest.sepaAgreement}
          disabled={disabled}
          name="agreement"
          description="Ich ermächtige die TUI Cruises GmbH Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der TUI Cruises GmbH auf mein Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen."
          onChange={(e) => this.handleAgreementChange(e.currentTarget.checked)}
          error={storeRequest.notAgreedToSepaError}
        />
        <Text type="xs">*Pflichtfelder</Text>
      </div>
    );
  }
}
