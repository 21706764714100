// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { itsUrlWithSize } from '../../utils/image';

import Headline from '../Headline';
import ItineraryDay from '../TravelPlan/ItineraryDay';
import TeaserStripForDay from '../TravelPlan/TeaserStripForDay';
import preload from '../../components/pages/decorators/preload';
import track from '../../tracking';

import type MasterStore from '../../stores/MasterStore';
import type ItineraryDayModel from '../../models/ItineraryDay';
import type TilesStore from '../../stores/TilesStore';

type Props = {
  masterStore: MasterStore,
  day: ItineraryDayModel,
  tilesStore: TilesStore,
};

@inject('masterStore', 'tilesStore')
@preload({ day: 'ItineraryDay' })
@observer
export default class PageTravelPlanDetails extends Component<Props> {
  static breadcrumb = 'Tagesplan';

  componentDidMount() {
    this.props.tilesStore.fetchAll();
    this.trackPageView();
  }

  trackPageView = () => {
    track.pageView(window.location.pathname, ['reiseplan', `tag-${this.props.day.day}`], 'search');
  };

  render() {
    const { day, masterStore, tilesStore } = this.props;
    const { masterData } = masterStore;
    const { tiles } = tilesStore;

    return (
      <div className="page">
        <Headline title={`${day.formattedDate}: ${day.port.name}`} />
        <div className="l-row">
          <div className="l-col left l-mod-sub">
            <img src={itsUrlWithSize(day.image, 544, 324)} alt={day.hint} />
          </div>
          <div className="l-col double">
            <p>{day.port.description}</p>
          </div>
        </div>
        <ItineraryDay masterData={masterData} day={day} />
        <TeaserStripForDay tiles={tiles} day={day} />
      </div>
    );
  }
}
