var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button } from '../Button';
import { IconLogout } from '../icons/IconLogout';
import { LogoMeinSchiff } from '../LogoMeinSchiff';
export var LayoutHeader = function (_a) {
    var logoProps = _a.logoProps, onClickLogout = _a.onClickLogout;
    var doLogout = useCallback(function (e) {
        e.preventDefault();
        e.stopPropagation();
        onClickLogout();
    }, [onClickLogout]);
    return (React.createElement("header", { className: "border-b border-stroke-secondary-40 border-solid bg-ocean-blue-100 md:h-auto px-4 xl:px-16" },
        React.createElement("div", { className: "h-[46px] flex items-center container md:justify-between py-6 md:h-16" },
            React.createElement("div", { className: "flex grow h-full items-center justify-start" },
                React.createElement("a", __assign({}, logoProps, { className: twMerge('flex h-full items-center', logoProps === null || logoProps === void 0 ? void 0 : logoProps.className) }),
                    React.createElement(LogoMeinSchiff, { className: "h-[30px] md:h-full", width: "auto", height: "100%" }))),
            React.createElement("a", { className: "flex md:hidden cursor-pointer rounded-full bg-marine-blue-100 p-3 text-white", onClick: doLogout },
                React.createElement(IconLogout, null)),
            React.createElement(Button, { as: "a", className: "cursor-pointer hidden md:block", variant: "dark", onClick: doLogout }, "Abmelden"))));
};
