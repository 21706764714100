// TODO: add flow types

import { observable, action, computed } from 'mobx';

export default class TypeStore {
  @observable items = null;
  @observable meta = null;

  masterStore = null;
  api = null;
  fetchListRequest = null;
  fetchDetailsRequest = {};

  constructor(api, masterStore, type, klass) {
    this.api = api;
    this.masterStore = masterStore;
    this.type = type;
    this.klass = klass;
  }

  @computed
  get isLoading() {
    return this.items === null;
  }

  @action
  fetchList() {
    if (!this.fetchListRequest) {
      this.fetchListRequest = this.api[`${this.type}List`](this.masterStore.user, this.masterStore.masterData).then(
        (response) => {
          return this.receiveListResponse(response);
        }
      );
    }
    return this.fetchListRequest;
  }

  @action
  receiveListResponse(response) {
    const Klass = this.klass;
    this.items = response.list.map((item) => new Klass(item, this.masterStore));
    this.meta = response.meta;
    return this.items;
  }

  @action
  fetchDetails(id) {
    const Klass = this.klass;
    if (!this.fetchDetailsRequest[id]) {
      this.fetchDetailsRequest[id] = this.api[`${this.type}Details`](
        this.masterStore.user,
        this.masterStore.masterData,
        id
      ).then((items) => new Klass(items, this.masterStore));
    }
    return this.fetchDetailsRequest[id];
  }

  get freeDataVolumeText() {
    if (!this.meta || !this.masterStore || !this.masterStore.masterData) return null;

    const {
      requestorHasFeelGoodPlusTariff,
      requestorHasProTariff,
      requestorHasPlusTariff,
      requestorHasVipOption1,
      requestorHasVipOption2,
    } = this.masterStore.masterData;

    let text350Mb = requestorHasFeelGoodPlusTariff && this.meta['350_mb_text'];
    let text350MbPro = requestorHasProTariff && this.meta['350_mb_text_pro'];
    let text100MbPlus = requestorHasPlusTariff && this.meta['100_mb_text_plus'];
    let text7GVip1 = requestorHasVipOption1 && this.meta['7_gb_text_vip1'];
    let text3GVip2 = requestorHasVipOption2 && this.meta['3_gb_text_vip2'];

    return text7GVip1 || text3GVip2 || text350MbPro || text350Mb || text100MbPlus;
  }
}
