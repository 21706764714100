// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import Headline from '../../Headline';

import LoadingIndicator from '../../LoadingIndicator';
import type BoardAccountStore from '../../../stores/BoardAccountStore';
import type TilesStore from '../../../stores/TilesStore';

import BookingTable from '../../BookingDetails/booking-table/BookingTable';
import InclusiveBookingTable from '../../BookingDetails/booking-table/InclusiveBookingTable';
import Tiles from '../../BookingDetails/Tiles';
import Button from '../../Button';
import isInAppView from '../../../utils/isInAppView';
import track from '../../../tracking';

import type MasterStore from '../../../stores/MasterStore';

type Props = {
  boardAccountStore: BoardAccountStore,
  tilesStore: TilesStore,
  masterStore: MasterStore,
};

@inject('boardAccountStore', 'tilesStore', 'masterStore')
@observer
export default class AdditionalServices extends Component<Props> {
  static breadcrumb = 'Zusatzleistungen';

  componentDidMount() {
    this.props.boardAccountStore.fetch();
    this.props.tilesStore.fetchAll();
    this.trackPageView();
  }
   
  trackPageView = () => {
    track.pageView(window.location.pathname, ['buchung-reiseunterlagen', 'zusatzleistungen'], 'sub-category');
  };

  @autobind
  handlePrint() {
    window.print();
  }

  render() {
    const { boardAccountStore, tilesStore, masterStore } = this.props;
    const { masterData } = masterStore;
    const { boardAccount } = boardAccountStore;
    const { tiles } = tilesStore;

    if (!boardAccount) return null;

    const {
      additionalBookings, // addons
      bookingTableHeadline, // addons
      includedBookingTableHeadline, // addons
      includedBookings, // addons
    } = boardAccount;

    return (
      <div className="page page-booking-details">
        <Headline className="l-mod" title="Zusatzleistungen" />

        {boardAccount ? (
          <div>
            <section className="l-mod">
              {includedBookings.list.length >= 1 && (
                <div>
                  <p>{includedBookingTableHeadline}</p>
                  <InclusiveBookingTable
                    hasVip1={masterData.someoneHasVipOption1}
                    hasVip2={masterData.someoneHasVipOption2}
                    items={includedBookings.list}
                  />
                </div>
              )}

              <p>{bookingTableHeadline}</p>

              <BookingTable
                items={additionalBookings.list}
                totalText={additionalBookings.totalText}
                emptyText={'Es wurden bisher keine Zusatzleistungen von Ihnen gebucht.'}
              />

              {additionalBookings.fineprint ? <p>{additionalBookings.fineprint}</p> : null}
            </section>

            {!isInAppView && (
              <div className="l-right l-mod">
                <Button dark big onClick={this.handlePrint}>
                  Drucken
                </Button>
              </div>
            )}
          </div>
        ) : (
          <LoadingIndicator />
        )}

        <Tiles tiles={tiles} masterData={masterData} />
      </div>
    );
  }
}
