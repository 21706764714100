// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import platform from 'platform';
import Headline from '../../Headline';
import Tile from '../../tiles/Tile';
import track from '../../../tracking';

import { width } from '../../../utils/screen';
import LoadingIndicator from '../../LoadingIndicator';

import type MasterStore from '../../../stores/MasterStore';
import type { TilesStoreType } from '../../../stores/TilesStore';

type Props = {
  masterStore: MasterStore,
  tilesStore: TilesStoreType,
};

@inject('masterStore', 'tilesStore')
@observer
export default class PageBookingDetails extends Component<Props> {
  static breadcrumb = 'Buchung & Reiseunterlagen';

  componentDidMount() {
    this.props.tilesStore.fetchAll();
    this.trackPageView();
  }

  trackPageView = () => {
    track.pageView(window.location.pathname, ['buchung-reiseunterlagen'], 'category');
  };

  render() {
    const { masterStore, tilesStore } = this.props;
    const { masterData } = masterStore;
    if (tilesStore.isLoading) {
      return <LoadingIndicator />;
    }
    const { tiles } = tilesStore;

    const showFullTile = platform.name === 'IE' || width >= 952;
    return (
      <div className="page page-booking-details">
        <Headline
          className="l-mod"
          title="Alle Buchungsdetails & Reiseunterlagen auf einen Blick"
          subtitle={masterData.subtitleLine}
        />
        <div className="l-tiles m-top">
          {tiles.anUndAbreise.visible ? (
            <Tile
              full
              badge={tiles.anUndAbreise.notification}
              disabled={!tiles.anUndAbreise.enabled}
              link="/buchungsdetails/an-und-abreise"
              title="An- & Abreise"
              icon="suitcase"
            />
          ) : null}
          {tiles.zusatzleistungen.visible ? (
            <Tile
              full={showFullTile}
              badge={tiles.zusatzleistungen.notification}
              disabled={!tiles.zusatzleistungen.enabled}
              link="/buchungsdetails/zusatzleistungen"
              title="Zusatzleistungen"
              icon="bookingdetails"
            />
          ) : null}
          {tiles.reisepreis.visible ? (
            <Tile
              full={showFullTile}
              badge={tiles.reisepreis.notification}
              disabled={!tiles.reisepreis.enabled}
              link="/buchungsdetails/reisepreis"
              title="Reisepreis"
              icon="payment"
            />
          ) : null}
          {masterData.requestorHasVipOption && (
            <Tile
              full={showFullTile}
              link={`/buchungsdetails/vip-tarif/${masterData.vipTariffId}`}
              title={masterData.vipTariffName}
              icon="vip-tariff"
            />
          )}
          {tiles.reiseunterlagen.visible ? (
            <Tile
              full={showFullTile}
              badge={tiles.reiseunterlagen.notification}
              disabled={!tiles.reiseunterlagen.enabled}
              link="/buchungsdetails/reiseunterlagen"
              title="Reiseunterlagen"
              icon="pdf-tile"
            />
          ) : null}
          {tiles.reiseversicherung.visible ? (
            <Tile
              full={showFullTile}
              badge={tiles.reiseversicherung.notification}
              disabled={!tiles.reiseversicherung.enabled}
              link="/buchungsdetails/reiseversicherung"
              title="Reiseversicherung"
              icon="shield"
            />
          ) : null}
        </div>
      </div>
    );
  }
}
