// @flow
import React, { Component } from 'react';

import InfoBox from '../../InfoBox';
import LinkAsButton from '../../LinkAsButton';
import HanseMerkurLogo from '../../../../assets/svg/inline/logo-hanse-merkur.inline.svg';
import isInAppView from '../../../utils/isInAppView';
import track from '../../../tracking';

export default class TravelInsurance extends Component {
  static breadcrumb = 'Reiseversicherung';

  componentDidMount() {
    track.pageView(window.location.pathname, ['buchung-reiseunterlagen', 'reiseversicherung'], 'sub-category');
  }

  render() {
    return (
      <div className="page page-booking-details page-insurance">
        <InfoBox noIcon>
          <div className="travel-insurance">
            <div className="head">
              <i className="icon shield" />
              <h2>Reiseversicherung</h2>
            </div>
          </div>
          {isInAppView && (
            <div className="insurance-in-app">Aktuell nur auf der Website und nicht der App verfügbar.</div>
          )}
          <p>
            Haben Sie an Ihren passenden Reiseschutz gedacht?
            <br />
            Sofern Sie über keinen Reise&shy;versicherungs&shy;schutz verfügen, kontaktieren Sie Ihr buchendes Reisebüro
            oder schließen Sie diesen über unseren Partner Hanse Merkur Reise&shy;versicherung AG ab.
          </p>
          <div className="l-row">
            <div className="l-col">
              <h3>Welche Versicherung passt zu Ihnen?</h3>
              <LinkAsButton
                dark
                newPage
                link="https://secure.hmrv.de/rvw-ba/initBa.jsp?baid=910&adnr=3654472&fTypId=0&locale=de_DE"
                disabled={isInAppView}
              >
                Zu den Reiseversicherungen
              </LinkAsButton>
              <br />
            </div>
            <div className="l-col right" />
          </div>
          <p>
            Kennen Sie den Corona-Zusatzschutz Solo der Hanse Merkur Reise&shy;versicherungen AG? Mit dem speziellen
            Corona-Zusatzschutz Solo, den Sie zusätzlich zu einer bereits bestehenden Reiserücktritts&shy;versicherung
            abschließen können, möchten wir Ihnen in diesen Zeiten die bestmögliche Absicherung für einen sorglosen
            Urlaub bieten.
          </p>
          <div className="l-row">
            <div className="l-col">
              <LinkAsButton
                dark
                newPage
                link="https://secure.hmrv.de/rvw-ba/initBa.jsp?baid=2002&subBaId=3&adnr=3654472&fTypId=0&locale=de_DE"
                disabled={isInAppView}
              >
                Corona-Zusatzschutz Solo
              </LinkAsButton>
              <br />
              <br />
            </div>
            <div className="l-col right">
              <h4>Unser Vertragspartner:</h4>
              <div className="l-center">
                <HanseMerkurLogo className="inline-svg" alt="Logo Hanse Merkur Reiseversicherung AG" />
                <br />
                <br />
              </div>
            </div>
          </div>
        </InfoBox>
      </div>
    );
  }
}
