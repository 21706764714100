// @flow

import React from 'react';
import { Route, IndexRoute, browserHistory, applyRouterMiddleware } from 'react-router';
import useScroll from 'react-router-scroll/lib/useScroll';
import BreadcrumbRouter from './components/breadcrumbs/BreadcrumbRouter';
import { doParameterLogin, requireAuthentication } from './auth';

import App from './App';
import Provider from './Provider';
import DefaultLayout from './DefaultLayout';
import * as Pages from './components/pages';

export default function AppRouter() {
  return (
    <Provider>
      <BreadcrumbRouter history={browserHistory} render={applyRouterMiddleware(useScroll(() => true))}>
        <Route onEnter={doParameterLogin}>
          <Route component={DefaultLayout}>
            <Route component={App} onEnter={requireAuthentication}>
              <Route path="/">
                <IndexRoute component={Pages.Home} />

                <Route component={Pages.WithBreadcrumb}>
                  <Route path="highlights/:highlightId" component={Pages.Highlights} />

                  <Route path="manifest">
                    <IndexRoute component={Pages.ManifestOverview} />
                    <Route path="einreisebestimmungen" component={Pages.ManifestRegulations} />
                    <Route path="manifest/:memberIndex" component={Pages.ManifestForm} />
                    <Route path="payment/:memberIndex" component={Pages.ManifestPayment} />
                    <Route path="health/:memberIndex" component={Pages.ManifestDigitalHealth} />
                  </Route>

                  <Route path="payment">
                    <IndexRoute component={Pages.PaymentOverview} />
                    <Route path=":memberIndex" component={Pages.PaymentMember} />
                  </Route>

                  <Route path="checkin">
                    <IndexRoute component={Pages.CheckInOverview} />
                    <Route path="checkin">
                      <IndexRoute component={Pages.CheckInProcess} />
                      <Route path="success" component={Pages.CheckInSuccess} />
                    </Route>
                    <Route path="manifest/:memberIndex" isCheckinSubRoute component={Pages.ManifestForm} />
                    <Route path="payment/:memberIndex" isCheckinSubRoute component={Pages.ManifestPayment} />
                    <Route path="health/:memberIndex" isCheckinSubRoute component={Pages.ManifestDigitalHealth} />
                  </Route>

                  <Route path="schoenheit-und-gesundheit">
                    <IndexRoute component={Pages.HealthAndBeauty} />

                    <Route path="beautysalon">
                      <IndexRoute component={Pages.BeautySalon} />
                      <Route path=":packageId" component={Pages.SpaDetails} />
                    </Route>

                    <Route path="spa">
                      <IndexRoute component={Pages.Spa} />
                      <Route path=":categoryId">
                        <IndexRoute component={Pages.SpaCategory} />
                        <Route path=":packageId" component={Pages.SpaDetails} />
                      </Route>
                    </Route>
                    <Route path=":type">
                      <IndexRoute component={Pages.HealthAndBeautyList} /> 
                      <Route path=":packageId" component={Pages.SpaDetails} />
                    </Route>
                  </Route>

                  <Route path="fitness">
                    <IndexRoute component={Pages.Fitness} />
                    <Route path=":categoryId">
                      <IndexRoute component={Pages.FitnessCategory} />
                      <Route path=":packageId" component={Pages.SpaDetails} />
                    </Route>
                  </Route>

                  <Route path="landausfluege">
                    <IndexRoute component={Pages.Excursion} />
                    <Route path=":dayIndex">
                      <IndexRoute component={Pages.ExcursionDay} />
                      <Route path=":excursionId" component={Pages.ExcursionDetails} />
                    </Route>
                  </Route>
                  <Route path="inklusiv-landausfluege">
                    <IndexRoute component={Pages.IncludedShoreEx} />
                    <Route path=":excursionId" component={Pages.IncludedShoreExDetails} />
                  </Route>

                  <Route path="reiseplan">
                    <IndexRoute component={Pages.TravelPlan} />
                    <Route path=":dayIndex">
                      <IndexRoute component={Pages.TravelPlanDetails} />
                      <Route path="landausflug/:excursionId" component={Pages.ExcursionDetails} />
                      <Route path="inklusiv-landausflug/:excursionId" component={Pages.IncludedShoreExDetails} />
                      <Route path="schoenheit-und-gesundheit/:packageId" component={Pages.SpaDetails} />
                      <Route path="tischreservierung/:venueId" component={Pages.RestaurantDetails} />
                      <Route path="programm/:eventId" component={Pages.ProgramDetails} />
                      <Route path="spa-balkonkabine/:packageId" component={Pages.SpaBalconyCabinDetails} />
                    </Route>
                  </Route>

                  <Route path="parkservice">
                    <IndexRoute component={Pages.Parking} />
                    <Route path=":packageId" component={Pages.ParkingDetails} />
                  </Route>

                  <Route path="reisemedien">
                    <IndexRoute component={Pages.TravelMedia} />
                    <Route path=":packageId" component={Pages.TravelMediaDetails} />
                  </Route>

                  <Route path="internet-an-bord">
                    <IndexRoute component={Pages.Internet} />
                    <Route path=":packageId" component={Pages.InternetDetails} />
                  </Route>

                  <Route path="tischreservierung">
                    <IndexRoute component={Pages.Restaurants} />
                    <Route path=":venueId" component={Pages.RestaurantDetails} />
                  </Route>

                  <Route path="pakete-und-zeitungen">
                    <IndexRoute component={Pages.NewspaperAndPackages} />
                    <Route path="zeitungen" component={Pages.Newspaper} />
                    <Route path="pakete">
                      <IndexRoute component={Pages.Packages} />
                      <Route path=":packageId" component={Pages.PackageDetails} />
                    </Route>
                  </Route>


                  <Route path="buchungsdetails">
                    <IndexRoute component={Pages.BookingDetails} />
                    <Route path="an-und-abreise" component={Pages.ArrivalDeparture} />
                    <Route path="zusatzleistungen" component={Pages.AdditionalServices} />
                    <Route path="reisepreis" component={Pages.TravelAward} />
                    <Route path="einreisebestimmungen" component={Pages.ManifestRegulations} />
                    <Route path="vip-tarif/:tariffId">
                      <IndexRoute component={Pages.VipTariff} />
                      <Route path="vip-gaeste">
                        <IndexRoute component={Pages.FlightVipGaeste} />
                        <Route path=":memberIndex" component={Pages.FlightPreference} />
                      </Route>
                    </Route>
                    <Route path="reiseunterlagen" component={Pages.TravelDocuments} />
                    <Route path="reiseversicherung" component={Pages.TravelInsurance} />
                  </Route>

                  <Route path="weitere-informationen" component={Pages.FurtherInformation} />

                  <Route path="spa-balkonkabine">
                    <IndexRoute component={Pages.SpaBalconyCabin} />
                    <Route path=":packageId" component={Pages.SpaBalconyCabinDetails} />
                  </Route>

                  <Route path="/fragebogen/:surveyId/:surveyTitle" component={Pages.SurveyDetail} />
                </Route>

                <Route path="/redirect/AncillaryDetails" component={Pages.AncillaryDetails} />
              </Route>
              <Route path="programm">
                <IndexRoute component={Pages.Program} />
                <Route path=":eventId" component={Pages.ProgramDetails} />
              </Route>
            </Route>

            <Route path="/login" component={Pages.Login} />
            <Route path="/maintenance" component={Pages.Maintenance} />
            <Route path="*" component={Pages.NotFound} />
          </Route>
        </Route>
      </BreadcrumbRouter>
    </Provider>
  );
}
