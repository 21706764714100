// TODO: add flow types

import flatten from 'lodash/flatten';
import { observable, computed, action } from 'mobx';

import {
  MASTERDATA_RELOAD_INTERVAL,
  MASTERDATA_RELOAD_ERROR_LIMIT,
  SURVEY_MPI_NOT_OK,
  SURVEY_MPI_NOT_OK_L1,
  SURVEY_MPI_NOT_OK_L2,
} from '../config/constants';

import MasterData from '../models/MasterData';
import BookedPackage from '../models/BookedPackage';
import Itinerary from '../models/Itinerary';

import track from '../tracking';

export default class MasterStore {
  @observable masterData = null;
  @observable itinerary = null;
  @observable loading = false;
  loadPromise = null;
  @observable loginError = null;
  @observable masterDataError = null;
  api = null;
  authStore = null;
  reloadErrorCounter = 0;
  _isInAppView = false;

  constructor(api, authStore) {
    this.api = api;
    this.authStore = authStore;
  }

  @action
  fetch() {
    this.loading = true;
    return this.authStore.fetchAuthenticatedUser().then((user) => {
      this.loadPromise = this.fetchMasterData(user).then((masterData) => {
        user.enhanceWithMasterData(masterData);
        return this.api.getItinerary(user, masterData).then(
          action((itinerary) => {
            this.updateFromServer(masterData);
            this.updateItineraryFromServer(itinerary);
            this.loading = false;
            this.loadPromise = null;
            return masterData;
          }),
          this.receiveLoginError
        );
      }, this.receiveMasterDataError);
      return this.loadPromise;
    }, this.receiveLoginError);
  }

  @action.bound
  receiveMasterDataError(err) {
    track.masterDataFailed(window.location.href);
    this.masterDataError = err;
  }

  @action.bound
  receiveLoginError(err) {
    track.masterDataFailed(window.location.href);
    this.loginError = err;
  }

  @action.bound
  onReloadError() {
    this.reloadErrorCounter++;
    // wait (MASTERDATA_RELOAD_ERROR_LIMIT + 1) * MASTERDATA_RELOAD_INTERVAL
    if (this.reloadErrorCounter > MASTERDATA_RELOAD_ERROR_LIMIT) {
      window.location.href = '/404';
    } else if (this._reloadTimeout) {
      this.startAutoReload();
    }
  }

  @action
  updateFromServer(masterData) {
    if (masterData) this.masterData = masterData;
  }

  @action
  updateItineraryFromServer(itinerary) {
    if (itinerary) {
      this.itinerary = new Itinerary(itinerary, this.masterData.journeyOngoing);
      this.masterData.itinerary = this.itinerary;
    }
  }

  startAutoReload() {
    this.stopAutoReload();
    this._reloadTimeout = window.setTimeout(() => {
      this.reloadMasterData();
    }, MASTERDATA_RELOAD_INTERVAL);
  }

  stopAutoReload() {
    window.clearTimeout(this._reloadTimeout);
    this._reloadTimeout = null;
  }

  /* TUICUNIT-3086: inject questionnaire data from a separate Feed on home checkin and manifest*/
  fetchMasterData(user) {
    let result;
    result = this.api.getMasterData(user).then((data) => {
      /* Fuck inApp Webview has a "/" at the end :( ?inAppView=null */
      if (
        window.location.pathname === '/' ||
        window.location.pathname === '/checkin' ||
        window.location.pathname === '/manifest' ||
        window.location.pathname === '/checkin/' ||
        window.location.pathname === '/manifest/'
      ) {
        return this.api.getSurveyData(user).then((surveyData) => {
          data.travelParty.forEach((item, index) => {
            data.travelParty[index].questionnaire = surveyData.find((survey) => {
              if (survey.mpi === item.mpi) {
                survey = this.partyMemberQuestionnaireData(survey);
                return survey;
              }
            });
          });
          return new MasterData(data, this.itinerary);
        });
      } else {
        return new MasterData(data, this.itinerary);
      }
    });
    return result;
    // return this.api.getMasterData(user).then(data => new MasterData(data, this.itinerary));
  }

  reloadMasterData() {
    if (this.loadPromise) return this.loadPromise;
    window.clearTimeout(this._reloadTimeout);
    this.loadPromise = this.fetchMasterData(this.user)
      .then((masterData) => {
        this.updateFromServer(masterData);
        this.loadPromise = null;
        if (this._reloadTimeout) this.startAutoReload();
        return this.masterData;
      })
      .catch(() => {
        track.masterDataFailed(window.location.href);
        this.onReloadError();
        this.loadPromise = null;
        return this.masterData;
      });
    return this.loadPromise;
  }

  reloadItinerary() {
    if (!this.masterData) return null;
    return this.api.getItinerary(this.user, this.masterData).then((itinerary) => {
      this.updateItineraryFromServer(itinerary);
    });
  }

  packagesOfType(type) {
    if (!this.packages) return [];
    return this.packages.filter((pkg) => pkg.subType === type);
  }

  @computed
  get user() {
    return (this.authStore && this.authStore.user) || null;
  }

  @computed
  get bookings() {
    if (!this.masterData) return null;
    return flatten(Object.values(this.masterData.bookings));
  }

  @computed
  get packages() {
    return ((this.masterData && this.masterData.packages) || []).map((data) => new BookedPackage(data));
  }

  @computed
  get packagesOfUser() {
    return ((this.masterData && this.masterData.packages) || [])
      .filter((pkg) => pkg.mpis.indexOf(this.user.id) !== -1)
      .map((data) => new BookedPackage(data));
  }

  @computed
  get travelParty() {
    return (this.masterData && this.masterData.travelParty) || null;
  }

  getTravelPartyMemberByMpi(mpi) {
    if (!this.travelParty) return null;
    return this.travelParty.find((m) => m.mpi === mpi);
  }

  getLoggedInUserInfo() {
    if (!this.user) return null;
    return this.getTravelPartyMemberByMpi(this.user.id);
  }

  // TUICUNIT-2681: more than one Not OK state, for whatever reason
  partyMemberQuestionnaireData(questionnaire) {
    if (questionnaire.status) {
      if (
        questionnaire.status === SURVEY_MPI_NOT_OK ||
        questionnaire.status === SURVEY_MPI_NOT_OK_L1 ||
        questionnaire.status === SURVEY_MPI_NOT_OK_L2
      ) {
        questionnaire.status = SURVEY_MPI_NOT_OK;
      }
    }
    return questionnaire;
  }
}
