// @flow
import type BookingRequestBase from '../models/BookingRequest/BookingRequestBase';
import type {
  ECommerceTrackingData,
  TrackingData,
  TrackingListItem,
  TrackingViewItemData,
  TrackingViewListData,
} from '../types/tracking';
import { tealiumIdFromString, tealiumString, tealiumValue } from '../utils/tracking';

/**
 * Tracking - E-Commerce
 *
 * Utils to handle e-commerce tracking events. deprecated?
 */
// export function trackViewItem(bookingRequest: BookingRequestBase, path: ?string) {
//   const result = {};
//
//   extractTrackingProducts(bookingRequest).map((product) => {
//     result.booking_category = (result.booking_category || []).concat(mapOfferCategory(product.category, path));
//     result.booking_name = (result.booking_name || []).concat(product.name.trim());
//     result.booking_price = (result.booking_price || []).concat(`${product.price}`);
//     result.booking_product_id = (result.booking_product_id || []).concat(product.id);
//     result.page_name = product.name.trim();
//     result.page_type = 'detail_pages';
//   });
//
//   if (window.utag && Object.keys(result).length !== 0) {
//     window.utag.view(result);
//   }
// }

export function trackBookingRequest(bookingRequest: BookingRequestBase, userId: ?number) {
  if (bookingRequest.isChangeRequest) changeRequest(userId, bookingRequest);
  else if (bookingRequest.isCancellation) cancellation(userId, bookingRequest);
  else purchase(userId, bookingRequest);
}

function mapOfferCategory(category: string, path: ?string): string {
  if (!path) return category;
  if (path.indexOf('/schoenheit-und-gesundheit/hairdresser/') === 0)
    return `Schönheit & Gesundheit/Hair SPA on Sea/${category}`;
  if (path.indexOf('/schoenheit-und-gesundheit/spa/') === 0) return `Schönheit & Gesundheit/Spa & Meer/${category}`;
  if (path.indexOf('/schoenheit-und-gesundheit/') === 0) return `Schönheit & Gesundheit/${category}`;
  if (path.indexOf('/fitness/') === 0) return `Fitness/${category}`;
  return category;
}

/**
 * Returns the tracking details of the product the bookingRequest will be purchasing/cancelling
 * @private
 */
// function extractTrackingProducts(bookingRequest: BookingRequestBase) {
//   return Array.isArray(bookingRequest.analyticsDetails)
//     ? bookingRequest.analyticsDetails
//     : [bookingRequest.analyticsDetails];
// }

/**
 * Generates a (fake) transaction id for the given vacancy.
 * Google Analytics requires such an id to associate refunds with purchases.
 * Since the actual IDs are not exposed by the backend we have to resort to
 * a generated one.
 * @private
 */
function getTransactionId(userId: ?number, id: string) {
  return `${String(userId)}-${id}`;
}

/**
 * Adds the given products and tracks a purchase action.
 * @private
 */
function purchase(userId: ?number, bookingRequest: BookingRequestBase) {
  const details = bookingRequest.purchaseDetails;
  const analyticsDetails = bookingRequest.analyticsDetails;
  const path = window.location.pathname;

  const result = {
    tealium_event: 'purchase',
    booking_number: analyticsDetails.id, // bookingId
    booking_price_total: analyticsDetails.price,
    value: 0, // to be calculated from items
    currency: 'EUR',
    items: [],
  };

  details.products.forEach((product) => {
    result.booking_category = (result.booking_category || []).concat(mapOfferCategory(product.category, path));
    result.booking_name = (result.booking_name || []).concat(product.name.trim());
    result.booking_price = (result.booking_price || []).concat(`${product.price}`);
    result.booking_product_id = (result.booking_product_id || []).concat(`${product.id}`);
    result.booking_quantity = (result.booking_quantity || []).concat(`${product.quantity}`);
    result.booking_type = 'booking';
    result.page_name = product.name.trim();
  });
  details.purchases.forEach((item) => {
    result.booking_revenue = (result.booking_revenue || []).concat(`${item.revenue.toFixed(2)}`);
    result.booking_id = (result.booking_id || []).concat(getTransactionId(userId, item.id));
  });


  if (window.utag && Object.keys(result).length !== 0) {
    window.utag.link(result);
  }
}

/**
 * Tracks the cancellation of a booking.
 * @private
 */
function cancellation(userId: ?number, bookingRequest: BookingRequestBase) {
  const { analyticsCategory } = bookingRequest.bookable;
  const details = bookingRequest.purchaseDetails;

  let page_name = analyticsCategory;
  if (bookingRequest.bookable.name) {
    page_name = bookingRequest.bookable.name;
  } else if (bookingRequest.bookable.headline) {
    page_name = bookingRequest.bookable.headline;
  }

  const result = {};

  details.refunds.forEach((item) => {
    result.booking_id = (result.booking_id || []).concat(getTransactionId(userId, item.id));

    result.booking_type = 'refund';
    result.page_name = page_name.trim();
  });

  if (window.utag && Object.keys(result).length !== 0) {
    result.page_type = 'refund';
    window.utag.view(result);
  }
}

/**
 * Sends a refund action and re-adds the given products.
 * @private
 */
function changeRequest(userId: ?number, bookingRequest: BookingRequestBase) {
  cancellation(userId, bookingRequest, 'Buchungsänderung', {
    nonInteraction: 1,
  });
  purchase(userId, bookingRequest, 'Buchungsänderung');
}

export function trackViewListItem(lastPageView: TrackingData | null, listData: TrackingViewListData) {
  const listCat = listData.categories.top
    ? `${listData.categories.top}.${listData.categories.sub}`
    : listData.categories.sub;

  const catId = tealiumIdFromString(listData.categories.sub);

  let trackingData: ECommerceTrackingData = {
    event_name: 'view_item_list',
    ec_list_code: tealiumString(listData.excursions.map((excursion) => excursion.categoryId ?? catId).join('|')),
    ec_list_name: tealiumString(listData.excursions.map((excursion) => excursion.category ?? listCat).join('|')),
    ec_list_page: tealiumString(listData.excursions.map(() => '1').join('|')),
    ec_list_position: tealiumString(listData.excursions.map((excursion, index) => String(index + 1)).join('|')),
    ec_code: tealiumString(listData.excursions.map((excursion) => excursion.code).join('|')),
    ec_name: tealiumString(listData.excursions.map((excursion) => excursion.name).join('|')),
    ec_coupon: '', //todo?
    ec_agency_code: tealiumString(listData.excursions.map((excursion) => excursion.operator.code).join('|')),
    currency: 'EUR',
    ec_discount: tealiumString(listData.excursions.map((excursion) => excursion.discount).join('|')), //todo?
    ec_brand: tealiumString(listData.excursions.map((excursion) => excursion.operator.name).join('|')),
    ec_region: tealiumString(listData.excursions.map(() => mapRegionToName(listData.cruise.region)).join('|')),
    ec_cruise_name: tealiumString(listData.excursions.map(() => listData.cruise.name).join('|')),
    ec_cruise_code: listData.excursions.map(() => listData.cruise.code).join('|'),
    ec_ship: tealiumString(listData.excursions.map(() => listData.ship.name).join('|')),
    ec_cabin_tariff: tealiumString(listData.excursions.map(() => listData.cruise.tariff).join('|')),
    item_category4: tealiumString(
      listData.excursions.map(() => listData.categories.top || listData.categories.sub).join('|')
    ),
    item_category5: tealiumString(listData.excursions.map(() => listData.categories.sub).join('|')),
    item_variant: tealiumString(listData.excursions.map(() => listData.cruise.travelPeriod).join('|')),
    ec_start_date: tealiumString(listData.excursions.map((excursion) => excursion.startDate).join('|')),
    value: tealiumString(listData.excursions.map((excursion) => excursion.value).join('|')),
    ec_quantity: tealiumString(listData.excursions.map((excursion) => excursion.quantity).join('|')),
  };

  if (lastPageView) {
    trackingData = { ...trackingData, ...lastPageView };
  }

  if (window.utag) {
    window.utag.link(trackingData);
  }
}

export function trackDetailedItem(
  lastPageView: TrackingData | null,
  listData: TrackingListItem | undefined,
  itemData: TrackingViewItemData
) {
  let trackingData: ECommerceTrackingData = {
    event_name: 'view_item',
    ec_list_code: tealiumString(String(listData?.code ?? '')),
    ec_list_name: tealiumString(listData?.name ?? ''),
    ec_list_page: listData?.page ?? '1',
    ec_list_position: listData?.position ?? '1',
    ec_code: tealiumString(String(itemData.excursion.code)),
    ec_name: tealiumString(itemData.excursion.name),
    ec_coupon: '', //todo?
    ec_agency_code: tealiumString(itemData.excursion.operator.code),
    currency: 'EUR',
    ec_discount: tealiumString(itemData.excursion.discount), //todo?
    ec_brand: tealiumString(itemData.excursion.operator.name),
    ec_region: tealiumString(mapRegionToName(itemData.cruise.region)),
    ec_cruise_name: tealiumString(itemData.cruise.name),
    ec_cruise_code: itemData.cruise.code,
    ec_ship: tealiumString(itemData.ship.name),
    ec_cabin_tariff: tealiumString(itemData.cruise.tariff),
    item_category4: tealiumString(itemData.categories.top),
    item_category5: tealiumString(itemData.categories.sub),
    item_variant: tealiumString(itemData.cruise.travelPeriod),
    ec_start_date: tealiumString(itemData.excursion.startDate),
    value: tealiumString(itemData.excursion.value),
    ec_quantity: tealiumString(itemData.excursion.quantity),
  };

  if (lastPageView) {
    trackingData = { ...trackingData, ...lastPageView };
  }

  if (window.utag) {
    window.utag.link(trackingData);
  }

  return trackingData;
}

export function trackAddToCart(lastViewedItem: ECommerceTrackingData | null, qty: number, price: number) {
  if (lastViewedItem === null) {
    return;
  }

  const trackingData: ECommerceTrackingData = {
    ...lastViewedItem,
    value: tealiumString(tealiumValue(String(price))),
    ec_quantity: tealiumString(String(qty)),
    event_name: 'add_to_cart',
  };

  if (window.utag) {
    window.utag.link(trackingData);
  }

  return trackingData;
}

export function trackPurchase(lastAddedItem: ECommerceTrackingData | null) {
  if (lastAddedItem === null) {
    return;
  }

  const trackingData: ECommerceTrackingData = {
    ...lastAddedItem,
    event_name: 'purchase',
  };

  if (window.utag) {
    window.utag.link(trackingData);
  }
}

export function mapRegionToName(regionCode: string): string {
  const regionMap = {
    AS: 'Asien',
    KA: 'Mittelamerika & Karibik',
    KN: 'Kanaren',
    MM: 'Südeuropa & Mittelmeer',
    NA: 'Nordamerika',
    NL: 'Nordland & Großbritannien',
    OB: 'Ostsee & Baltikum',
    OR: 'Orient',
    WE: 'Westeuropa',
  };

  return regionMap[regionCode] ?? '';
}
