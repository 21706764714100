// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from './redesign/Checkbox';

export default class CheckboxGroupItem extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    info: PropTypes.node,
    onChange: PropTypes.func,
    children: PropTypes.node.isRequired,
  };

  render() {
    const { value, info, onChange, disabled, children } = this.props;
    const inputProps = {};

    if ('defaultChecked' in this.props) inputProps.defaultChecked = this.props.defaultChecked;
    else inputProps.checked = this.props.checked;

    return (
      <Checkbox
        title={children}
        description={info}
        value={value}
        disabled={disabled}
        {...inputProps}
        onChange={onChange}
      />
    );
  }
}
