// @flow
import { observable, computed, action } from 'mobx';
import areRangesOverlapping from 'date-fns/are_ranges_overlapping';
import compareDesc from 'date-fns/compare_desc';

import PartyMember from '../../models/PartyMember';
import Booking from '../../models/Booking';

import type DailyEvent from '../../models/DailyEvent';
import type Excursion from '../../models/Excursion';
import type InternetPackage from '../../models/InternetPackage';
import type NewspaperList from '../../models/NewspaperList';
import type Package from '../../models/Package';
import type RestaurantOffer from '../../models/RestaurantOffer';
import type SpaOffer from '../../models/SpaOffer';
import type ParkingPackage from '../../models/ParkingPackage';
import type IncludedShoreExDetail from '../../models/IncludedShoreEx/IncludedShoreExDetail';

type Bookable =
  | DailyEvent
  | Excursion
  | InternetPackage
  | NewspaperList
  | Package
  | RestaurantOffer
  | SpaOffer
  | ParkingPackage
  | IncludedShoreExDetail;

import type {
  ISeatBookingData,
  IGeneralBookingData,
  INewspaperBookingData,
  IGeneralItemToBook,
  INewspaperItemToBook,
  IBookingCancelationData,
  IPurchaseDetails,
  IAnalyticsDetails,
} from '../../types/booking';

interface IBookingRequest {
  +isChangeRequest: boolean;
  +isCancellation: boolean;
  +dataHasChanged: boolean;
  +isReady: boolean;
  +total: ?number;
  +date: string;
  +startDateTime: ?Date;
  +endDateTime: ?Date;
  +bookingData: ISeatBookingData[] | IGeneralBookingData[] | INewspaperBookingData[];
  +itemsToBook: IGeneralItemToBook[] | INewspaperItemToBook[];
  +cancelData: IBookingCancelationData[];
  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  +purchaseDetails: IPurchaseDetails;
  +getBookingConflicts: (bookings: Booking[]) => BookingConflict[];
}

type BookingConflict = BookingConflict & {
  conflictedMpis: number[],
};

export default class BookingRequestBase implements IBookingRequest {
  static TEXTS: Object = {
    listingType: 'Teilnehmer',
    create: {
      options: {
        title: 'Ihre Reservierungsanfrage',
      },
      confirmation: {
        title: 'Ihre Reservierungsanfrage',
        text: 'Bitte überprüfen Sie noch einmal Ihre Angaben:',
      },
      success: {
        title: 'Ihre Anfrage ist eingegangen.',
        text: 'Sobald Ihre Anfrage von uns bearbeitet wurde, wird diese in Ihrem Reiseplan angezeigt.',
      },
    },
    change: {
      options: {
        title: 'Ihre Bearbeitungsanfrage',
      },
      confirmation: {
        title: 'Ihre Bearbeitungsanfrage',
        text: 'Möchten Sie folgende Bearbeitung der Reservierung verbindlich absenden?',
      },
      success: {
        title: 'Ihre Bearbeitungsanfrage ist eingegangen.',
        text: 'Ihre Änderung ist bei uns eingegangen. Den aktuellen Status können Sie in Ihrem Reiseplan einsehen.',
      },
    },
    cancellation: {
      options: {
        title: 'Ihre Stornierungsanfrage',
      },
      confirmation: {
        title: 'Ihre Stornierungsanfrage',
        text: 'Möchten Sie die Reservierung wirklich für folgende Reiseteilnehmer stornieren?',
      },
      success: {
        title: 'Ihre Stornierung ist eingegangen.',
        text: 'Sobald Ihr Stornierungswunsch von uns bearbeitet wurde, wird die Leistung aus Ihrem Reiseplan entfernt.',
      },
    },
  };

  @observable errors: ?string = null;
  @observable isRequesting = false;
  @observable requestSuccessful = false;
  @observable selectedMpis: Array<any> = [];

  travelParty: PartyMember[];
  bookable: Bookable;

  constructor(travelParty: PartyMember[], bookable: Bookable) {
    this.travelParty = travelParty;
    this.bookable = bookable;
  }

  getBookingConflicts(bookings: Booking[]): BookingConflict[] {
    let conflictedMpisTemp: Array<any> = [];
    return bookings.reduce((acc, booking: Booking) => {
      if (
        !this.startDateTime ||
        !this.endDateTime ||
        !booking.startDateTime ||
        !booking.endDateTime ||
        !this.bookable.bookingId ||
        parseInt(this.bookable.bookingId, 10) === parseInt(booking.contentId, 10)
      ) {
        return acc;
      }

      // SUPTUICMRS-2667: omit issues when endDate is before startDate
      const datesAreSound =
        compareDesc(this.startDateTime, this.endDateTime) === 1 &&
        compareDesc(booking.startDateTime, booking.endDateTime) === 1;

      // check dates
      let datesAreOverlapping = false;
      try {
        datesAreOverlapping = areRangesOverlapping(
          this.startDateTime,
          this.endDateTime,
          booking.startDateTime,
          booking.endDateTime
        );
      } catch (error) {
        datesAreOverlapping = false;
      }

      const checkMpis = !!(this.selectedMpis && booking.mpis);

      booking.mpis.forEach((m) => {
        if (this.selectedMpis.indexOf(m) > -1) {
          conflictedMpisTemp.push(m);
        }
      });

      if (datesAreSound && datesAreOverlapping && (!checkMpis || conflictedMpisTemp.length)) {
        acc.push({
          ...booking,
          conflictedMpis: conflictedMpisTemp,
        });
      }
      return acc;
    }, []);
  }

  getSimpleText(key: string): string {
    return (
      (this.constructor.TEXTS && this.constructor.TEXTS[key]) ||
      (BookingRequestBase.TEXTS && BookingRequestBase.TEXTS[key])
    );
  }

  getTextForPage(key: string, textType: string): string {
    let subTexts = this.constructor.TEXTS.create;
    if (this.isCancellation) {
      subTexts = this.constructor.TEXTS.cancellation;
    } else if (this.isChangeRequest) {
      subTexts = this.constructor.TEXTS.change;
    }
    return subTexts[key][textType];
  }

  getText(key: string, textType: string): string {
    const text = !textType ? this.getSimpleText(key) : this.getTextForPage(key, textType);
    if (typeof text === 'function') {
      return text(this);
    }
    return text;
  }

  get showIndividualPrices(): boolean {
    return true;
  }

  @action
  // eslint-disable-next-line no-unused-vars
  cancelBooking(booking: ?Booking) {
    throw new Error('implement me');
  }

  @action
  markAsSuccessful() {
    this.requestSuccessful = true;
  }

  @action
  markAsRequesting(value: boolean) {
    this.isRequesting = value;
  }

  @action
  cleanErrors() {
    this.errors = null;
  }

  @action
  setErrors(errors: string) {
    this.errors = errors;
  }

  resetRequest() {}

  reset() {}

  // eslint-disable-next-line no-unused-vars
  selectParticipants(mpi: number, value: boolean) {}

  // eslint-disable-next-line no-unused-vars
  chooseParticipant(mpi: ?number) {}

  // eslint-disable-next-line no-unused-vars
  setAcceptation(value: any) {}

  removeDay() {}

  get showBookingTable(): boolean {
    return true;
  }

  // eslint-disable-next-line no-unused-vars
  getPriceForMember(member: PartyMember): number {
    return this.bookable.price;
  }

  @computed
  get isChangeRequest(): boolean {
    throw new Error('implement me');
  }

  @computed
  get isCancellation(): boolean {
    throw new Error('implement me');
  }

  get date(): string {
    return '';
  }

  get startDateTime(): ?Date {
    return this.bookable.startDateTime;
  }

  get endDateTime(): ?Date {
    return this.bookable.endDateTime;
  }

  @computed
  get itemsToBook(): IGeneralItemToBook[] | INewspaperItemToBook[] {
    throw new Error('implement me');
  }

  @computed
  get total(): ?number {
    throw new Error('implement me');
  }

  @computed
  get dataHasChanged(): boolean {
    throw new Error('implement me');
  }

  @computed
  get isReady(): boolean {
    throw new Error('implement me');
  }

  @computed
  get hasErrors(): boolean {
    return !!this.errors;
  }

  @computed
  get bookingData(): ISeatBookingData[] | IGeneralBookingData[] | INewspaperBookingData[] | Object[] {
    throw new Error('implement me');
  }

  @computed
  get cancelData(): IBookingCancelationData[] {
    throw new Error('implement me');
  }

  @computed
  get vacancyId(): ?string {
    return this.bookable.vacancyId;
  }

  get analyticsDetails(): IAnalyticsDetails | IAnalyticsDetails[] {
    const member = this.travelParty.find((p) => p.requestor);
    return {
      id: `${this.bookable.bookingId}`,
      name: this.bookable.name,
      category: this.bookable.analyticsCategory,
      price: member ? this.getPriceForMember(member) : 0,
    };
  }

  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  get purchaseDetails(): IPurchaseDetails {
    throw new Error('implement me');
  }

  get bookingContextName(): string {
    return '';
  }
}
