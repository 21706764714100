// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import BreadcrumbLink from '../../breadcrumbs/BreadcrumbLink';
import ExcursionInfoList from '../../ExcursionInfoList';
import BookingAssistant from '../../BookingAssistant/InclusiveShoreExBookingAssistant';
import WithBookingConfirmation from '../../BookingConfirmation/WithBookingConfirmation';
import IncludedShoreExBookingRequest from '../../../models/BookingRequest/IncludedShoreExBookingRequest';
import preload from '../../../components/pages/decorators/preload';

import type MasterStore from '../../../stores/MasterStore';
import type BookingUiStore from '../../../stores/BookingUiStore';
import type ItineraryDay from '../../../models/ItineraryDay';
import { getSlideDirectionString } from '../../../utils/sliderHelper';
import track from '../../../tracking';
import Headline from '../../Headline';
import ImageSlider from '../../ImageSlider';
import Badge from '../../Badge';
import IncludedShoreExStore from '../../../stores/IncludedShoreExStore';
import LoadingIndicator from '../../LoadingIndicator';
import IncludedShoreExDetail from '../../../models/IncludedShoreEx/IncludedShoreExDetail';
import IncludedShoreExDetailStatus from '../../../models/IncludedShoreEx/IncludedShoreExDetailStatus';
import { observable } from 'mobx';

@inject('masterStore')
@preload(
  {
    details: 'IncludedShoreExDetail',
  },
  false
)
@observer
class IncludedShoreExDetailsBreadcrumb extends Component<{
  masterStore: MasterStore,
  details: IncludedShoreExDetail,
}> {
  render() {
    const { details, masterStore } = this.props;
    return details && masterStore && masterStore.masterData ? (
      <BreadcrumbLink {...this.props} text={details.name} />
    ) : null;
  }
}

type Props = {
  masterStore: MasterStore,
  bookingUiStore: BookingUiStore,
  includedShoreExStore: IncludedShoreExStore,
  details: IncludedShoreExDetail,
  status: IncludedShoreExDetailStatus,
  day: ItineraryDay,
  bookingList: Function,
  bottomPart: Function,
  infoList: Function,
  sidebar: Function,
  params: { excursionId: string },
};

@inject('masterStore', 'bookingUiStore', 'includedShoreExStore')
@preload({ details: 'IncludedShoreExDetail' })
@preload({ status: 'IncludedShoreExDetailStatus' })
@observer
export default class PageIncludedShoreExDetails extends Component<Props> {
  static breadcrumb = IncludedShoreExDetailsBreadcrumb;

  @observable bookingRequest: IncludedShoreExBookingRequest;

  constructor(props: Props) {
    super(props);
    const { details, masterStore, status } = this.props;

    this.bookingRequest = new IncludedShoreExBookingRequest(
      masterStore.masterData.travelParty,
      masterStore.masterData.edFull,
      details,
      status
    );

    const { bookingUiStore } = props;
    bookingUiStore.reset();
  }

  @autobind
  handleBookingCancelClick() {
    const { bookingUiStore } = this.props;
    this.bookingRequest.cancelBooking();
    bookingUiStore.openBookingDialog();
  }

  componentDidMount() {
    track.pageView(window.location.pathname, [])
    setTimeout(() => {
      this.trackPageView();
    }, 0);
  }

  trackPageView = () => {
    const { breadcrumbRouterStore, location } = this.props;
    const titles = breadcrumbRouterStore.getWithoutFirst;

    if (titles.length) {
      track.pageView(location.pathname, titles, 'product');
    }
  };

  render() {
    const {
      masterStore,
      bookingUiStore,
      details,
      status,
      bottomPart,
      includedShoreExStore,
    } = this.props;

    const { inclusiveBooking } = status;
    const { statusText } = inclusiveBooking;
    const { masterData } = masterStore;

    if (!details) return <LoadingIndicator />;

    return (
      <WithBookingConfirmation
        bookingRequest={this.bookingRequest}
        bookingStore={includedShoreExStore}
      >
        <div className="page inclusive-excursion">
          <Headline title={details.name} />

          <div className="badge-container">
            <ImageSlider
              image={details.images[0].image}
              images={[...details.images]}
              beforeChange={(oldIndex, newIndex) => {
                track.event(
                  'Inklusiv-Landausflug',
                  `Detailseite_Slider_${getSlideDirectionString(
                    oldIndex,
                    newIndex
                  )}`,
                  details.name
                );
              }}
              imageBadge={
                <div
                  className={`top-position ${(details.displayOperator &&
                    details.displayOperator.operatorClassName) ||
                    ''}`}
                />
              }
            />
            {statusText && <Badge bookable-info>{statusText}</Badge>}
          </div>
          <div className="l-row">
            <div className="l-col double l-mod">
              {details.headline && details.headline !== details.name ? (
                <h2>{details.headline}</h2>
              ) : null}
              {details.description && (
                <p
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: details.descriptionHtml || details.description,
                  }}
                />
              )}
              {<ExcursionInfoList model={details} />}
            </div>
            <BookingAssistant
              details={details}
              status={status}
              showParticipants={false}
              travelParty={masterData.travelParty}
              onBooking={bookingUiStore.openBookingOptions}
              onChangeBooking={bookingUiStore.openBookingOptions}
            />
          </div>
          {bottomPart ? bottomPart(details) : null}
        </div>
      </WithBookingConfirmation>
    );
  }
}
