// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import Link from 'react-router/lib/Link';
import Slider from 'react-slick';

import Badge from '../Badge';
import Price from '../Price';
import ITSPicture from '../ITSPicture';
import { itsUrlWithSize } from '../../utils/image';
import { isTouchScreen } from '../../utils/touch';
import type Booking from '../../models/Booking';
import sliderSettings from '../../config/sliderSettings';

type Props = {
  // The name of the harbor the ship will be docked at
  portName?: string,
  // The title of that catalog tile
  title: string,
  // Clicking on a tile will open this link
  link: string,
  // If we want show a bit more text here
  description?: string,
  // Source of Image if needed
  imageSrc?: ?string,
  // optional image slideShow
  images?: ?$ReadOnlyArray<{
    image: string,
    comment?: string,
  }>,
  onSlideShowChange?: Function,
  // If the item is not available offer the reason here
  unavailableBadge?: ?string,
  // If the price is starting with that price, we will show an 'ab'
  isStartingPrice?: boolean,
  // If we want to show a price please insert the price as string with €
  price?: ?(string | number),
  // hightlight for seenotsuebung
  zeroPriceMeansInclusive?: boolean,
  // If we the price is calculated per unit, add the unit here
  unit?: string,
  // Something to show in the footer if needed
  footer?: Node,
  // Is this thing booked?
  isBooked?: boolean, // deprecated - replace with 'booking'
  // if booked, the Booking
  booking?: Booking,
  // Should this tile be mada disabled
  disabled?: boolean,
  // hightlight for seenotsuebung
  highlight?: boolean,
  // rating for old footer
  category?: string,
  nappStyle?: ?boolean,
  imageBadge?: ?Node,
  isBookedBadge?: string,
  badge?: string,
  newPage?: ?boolean,
  large?: ?boolean,
  showPrice?: ?boolean,
  prelimenary?: ?boolean,
};

const sliderConfig = {
  ...sliderSettings,
  dots: false,
};

const imageSize = (large = false) => ({
  height: large ? 283 : 153,
  width: large ? 580 : 272,
});

const CatalogTile = ({
  portName,
  title,
  description,
  link,
  imageSrc,
  images,
  onSlideShowChange,
  isStartingPrice,
  price,
  zeroPriceMeansInclusive,
  unit,
  unavailableBadge,
  footer,
  isBooked,
  booking,
  disabled,
  highlight,
  category,
  nappStyle,
  imageBadge,
  isBookedBadge,
  badge,
  newPage,
  large,
  showPrice = true,
  prelimenary,
}: Props) => (
  <Link
    target={newPage ? '_blank' : ''}
    to={link}
    className={classNames({
      'catalog-tile': true,
      'has-badge': booking || unavailableBadge,
      'has-price': price || highlight,
      napp: !!nappStyle,
      'no-hover': isTouchScreen(),
      highlight: highlight,
      disabled,
      large,
    })}
  >
    {images ? (
      <div
        className={classNames({
          'slider-container': true,
          'gallery-tile': true,
          'large-tile': large,
        })}
      >
        <Slider {...sliderConfig} beforeChange={onSlideShowChange}>
          {images.map((img, i) => (
            <div key={i}>
              <img src={itsUrlWithSize(img.image, imageSize(large).width, imageSize(large).height)} alt={img.comment} />
            </div>
          ))}
        </Slider>
        {imageBadge ? imageBadge : null}
      </div>
    ) : (
      <div
        className={classNames({
          'catalog-tile__image': true,
          'large-tile': large,
        })}
      >
        {nappStyle ? (
          <img src={itsUrlWithSize(imageSrc, imageSize(large).width, imageSize(large).height)} />
        ) : (
          <ITSPicture
            src={imageSrc || ''}
            width={imageSize(large).width}
            height={imageSize(large).height}
            squareSize={71}
          />
        )}
        {imageBadge ? imageBadge : null}
      </div>
    )}

    <div
      className={classNames({
        'catalog-tile__content': true,
        'has-image': !!imageSrc,
        'has-slide-show': !!images,
        large,
      })}
    >
      <div>
        {category ? <span>{category}</span> : null}

        {portName && (
          <div
            className={classNames({
              'catalog-tile__overline': true,
            })}
          >
            <i className={'icon anchor'} />
            <h4>{portName}</h4>
          </div>
        )}

        {title.length > 58 ? (
          <h3>
            {title.substring(0, 58)}
            ...
          </h3>
        ) : (
          <h3>{title}</h3>
        )}

        {description ? <span>{description}</span> : null}
      </div>
      <div className="catalog-tile__footer">
        {footer ? <div className="catalog-tile__footer__content">{footer}</div> : null}
        {price && showPrice ? <Price price={price} unit={unit} label={isStartingPrice ? 'ab' : null} /> : null}
        {zeroPriceMeansInclusive && price === 0 ? <div className="price-inclusive">Inklusive</div> : null}
        {(booking || isBooked) && highlight ? <div className="price-inclusive">Inklusive</div> : null}
      </div>
    </div>
    {booking || isBooked ? (
      <Badge booked>
        {(booking && booking.prelimenary) || prelimenary ? 'in Bearbeitung' : isBookedBadge || 'Reserviert'}
      </Badge>
    ) : null}
    {!(booking || isBooked) && unavailableBadge ? <Badge unavailable>{unavailableBadge}</Badge> : null}
    {badge ? <Badge>{badge}</Badge> : null}
  </Link>
);

export default CatalogTile;
